import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Predictions from "./pages/Predictions";
import Matches from "./pages/Matches";
import Shop from "./pages/Shop";
import Leaderboard from "./pages/Leaderboard";
import About from "./pages/About";
import Teams from "./pages/Teams";
import Player from "./pages/Player";
import Trivia from "./pages/Trivia";
import Kit from "./pages/Kit";
import "../styles/App.css";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/predictions" element={<Predictions />} />
          <Route path="/matches" element={<Matches />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/about" element={<About />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/player" element={<Player />} />
          <Route path="/trivia" element={<Trivia />} />
          <Route path="/kit" element={<Kit />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
