import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import LoginDialog from "./LoginDialog";
import GroupDialog from "./GroupDialog";
import { GroupMember, UserDetails } from "../types";
import GroupRow from "./GroupRow";
import JoinGroupDialog from "./JoinGroupDialog";
import { useSearchParams } from "react-router-dom";

type Props = {
  groupMembers: GroupMember[];
  usersDetails: UserDetails[];
};

const MyGroups = ({ groupMembers, usersDetails }: Props) => {
  const [openGroup, setOpenGroup] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openJoin, setOpenJoin] = useState(false);
  const { user } = useAuth0();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const onClickHandler = () => {
    if (user) {
      setOpenGroup(true);
    } else {
      setOpenLogin(true);
    }
  };

  const onClickJoinHandler = () => {
    if (user) {
      setOpenJoin(true);
    } else {
      setOpenLogin(true);
    }
  };

  useEffect(() => {
    if (code && user?.sub) {
      setOpenJoin(true);
    }
  }, [code, user]);

  return (
    <>
      <Box mb={2} className="sizing">
        <Typography
          variant="h5"
          className="section-header"
        >{`my groups`}</Typography>
        <Box mt={1}>
          <Typography>{`Create or join a group to compare points.`}</Typography>
        </Box>
        <Box display="flex">
          <Box mr={2}>
            <Button variant="contained" onClick={onClickHandler}>
              Create Group
            </Button>
          </Box>
          <Button variant="contained" onClick={onClickJoinHandler}>
            Join Group
          </Button>
        </Box>
        <Box>
          {groupMembers.map((g) => {
            return <GroupRow g={g} usersDetails={usersDetails} />;
          })}
        </Box>
      </Box>
      <GroupDialog open={openGroup} setOpen={setOpenGroup} type="create" />

      <LoginDialog
        open={openLogin}
        setOpen={setOpenLogin}
        title="Groups"
        description="You must have an account in order to use groups."
      />

      <JoinGroupDialog open={openJoin} setOpen={setOpenJoin} urlCode={code} />
    </>
  );
};

export default MyGroups;
