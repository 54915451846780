import React from "react";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import { Invite } from "../types";
import { createOrUpdateInvite } from "../services/user";
import { useMutation, useQueryClient } from "react-query";

type Props = {
  invite: Invite;
};

const InviteRow = ({ invite }: Props) => {
  const queryClient = useQueryClient();
  const { isLoading: isLoadingAccept, mutate: acceptInviteMuation } =
    useMutation(
      async () => {
        return await createOrUpdateInvite({
          inviter_auth0_id: invite.inviter_auth0_id,
          email: invite.invitee_email,
          status: "accepted",
          id: invite.id,
        });
      },
      {
        onSuccess: () => {
          queryClient.fetchQuery("get-invites");
          queryClient.fetchQuery("get-leaderboard");
        },
      }
    );

  const { isLoading: isLoadingIgnore, mutate: ignoreInviteMuation } =
    useMutation(
      async () => {
        return await createOrUpdateInvite({
          inviter_auth0_id: invite.inviter_auth0_id,
          email: invite.invitee_email,
          status: "ignored",
          id: invite.id,
        });
      },
      {
        onSuccess: () => {
          queryClient.fetchQuery("get-invites");
        },
      }
    );

  const handleClickAccept = () => {
    acceptInviteMuation();
  };

  const handleClickIgnore = () => {
    ignoreInviteMuation();
  };

  return (
    <Box display="flex" m={2} flexDirection="column">
      <Typography>{invite.inviter.email}</Typography>

      <Box display="flex">
        <Box mr={1}>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleClickAccept()}
            disabled={isLoadingAccept || isLoadingIgnore ? true : false}
          >
            {isLoadingAccept && (
              <CircularProgress
                color="inherit"
                className="spinner"
                size="1rem"
              />
            )}
            Accept
          </Button>
        </Box>
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleClickIgnore()}
          disabled={isLoadingAccept || isLoadingIgnore ? true : false}
        >
          {isLoadingIgnore && (
            <CircularProgress color="inherit" className="spinner" size="1rem" />
          )}
          Ignore
        </Button>
      </Box>
    </Box>
  );
};

export default InviteRow;
