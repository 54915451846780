import React, { useState, useContext } from "react";
import { CompetitionContext } from "../context/CompetitionContext";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { UserDetails } from "../types";
import AlertDialog from "./AlertDialog";
import { useMutation, useQueryClient } from "react-query";
import { deleteGroupMember } from "../services/group";
import { formatLocalDateShort } from "../services/util";
import { statusMapping } from "../constants";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userDetails: UserDetails;
  isOwner: boolean;
  isSameUser: boolean;
  groupId?: string;
  groupName?: string;
  isGlobal?: boolean;
};

const UserDetailsDialog = ({
  open,
  setOpen,
  userDetails,
  isOwner,
  isSameUser,
  groupId,
  groupName,
  isGlobal,
}: Props) => {
  const competitionContext = useContext(CompetitionContext);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const queryClient = useQueryClient();
  const handleClose = () => {
    setOpen(false);
  };

  const { isLoading, mutate: deleteGroupMemberMutation } = useMutation(
    async () => {
      if (groupId) {
        return await deleteGroupMember({
          auth0_id: userDetails.auth0_id,
          group_id: groupId,
        });
      }
    },
    {
      onSuccess: () => {
        setOpenDeleteAlert(false);
        queryClient.fetchQuery("get-leaderboard");
      },
    }
  );

  const handleDeleteOtherMember = () => {
    setOpenDeleteAlert(true);
  };

  const handleDelete = async () => {
    await deleteGroupMemberMutation();
    setOpen(false);
  };

  const filteredPredictions = userDetails.prediction.filter(
    (item) => item.status !== "canceled"
  );

  const getWinPercentage = () => {
    let wins = 0;
    let losses = 0;
    filteredPredictions.forEach((p) => {
      if (p.status.includes("settled")) {
        if (p.is_winner) {
          wins += 1;
        } else {
          losses += 1;
        }
      }
    });

    if (wins + losses === 0) {
      return null;
    }

    return `${Math.floor((wins / (wins + losses)) * 100)}%`;
  };

  const winPercent = getWinPercentage();

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              {" "}
              {isGlobal
                ? userDetails.display_name || "Anonymous"
                : userDetails.display_name || userDetails.email}
            </Box>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box minWidth={"250px"}>
            {userDetails.winner_source_team_id &&
              competitionContext?.competition?.name === "world cup 2023" && (
                <Typography>{`🏆 ${userDetails.winner.name} ${userDetails.winner.logo}`}</Typography>
              )}

            {winPercent && (
              <Typography>{`Win Percentage: ${winPercent}`}</Typography>
            )}

            <Box mt={2}>
              <Typography className="bold">Predictions</Typography>
              {filteredPredictions.length > 0 ? (
                <TableContainer className="leaderboard-container">
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Date</TableCell>
                        <TableCell align="left">Match</TableCell>
                        <TableCell align="right">Result</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredPredictions.map((p, i) => {
                        const pointDifference =
                          p.is_winner === true
                            ? `+${p.points_bet}`
                            : `-${p.points_bet}`;
                        return (
                          <TableRow key={p.id}>
                            <TableCell
                              component="th"
                              scope="row"
                              className="small rank"
                            >
                              {formatLocalDateShort(p.fixture.dt_start)}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              className="small leaderboard-name"
                            >
                              {`${p.fixture.home_team.name} v. ${p.fixture.away_team.name}`}
                            </TableCell>
                            <TableCell align="right" className="small">
                              <Chip
                                size="small"
                                label={
                                  `${statusMapping[p.status]?.label} ${
                                    p.status.includes("settled")
                                      ? pointDifference
                                      : ""
                                  }` || ""
                                }
                                className="group-chip"
                                style={{
                                  backgroundColor: `${
                                    statusMapping[p.status]?.color || undefined
                                  }`,
                                  marginRight: 0,
                                  paddingRight: 0,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography style={{ fontStyle: "italic" }}>
                  no predictions yet
                </Typography>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" width="100%" justifyContent="center" mb={1}>
            {isOwner && !isSameUser && groupId && (
              <Box mr={2}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleDeleteOtherMember}
                >
                  Remove Member
                </Button>
              </Box>
            )}
            <Button onClick={handleClose} variant="contained">
              {`Close`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      {openDeleteAlert && (
        <AlertDialog
          open={openDeleteAlert}
          setOpen={setOpenDeleteAlert}
          buttonText={"confirm"}
          content={`Are you sure you want to remove ${
            userDetails.display_name || userDetails.email
          } from ${groupName}?`}
          buttonClickHandler={handleDelete}
          title="Confirm Remove Member"
          isLoading={isLoading}
        />
      )}
    </div>
  );
};
export default UserDetailsDialog;
