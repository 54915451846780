import React, { useState, useContext } from "react";
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import { createOrUpdateGroup } from "../services/group";
import { useMutation, useQueryClient } from "react-query";
import AlertDialog from "./AlertDialog";
import { Group } from "../types";
import { CompetitionContext } from "../context/CompetitionContext";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: "create" | "update";
  group?: Group;
};

const GroupDialog = ({ open, setOpen, type, group }: Props) => {
  const competitionContext = useContext(CompetitionContext);
  const [nameError, setNameError] = useState("");
  const [name, setName] = useState(group?.name || "");
  const [description, setDescription] = useState(group?.description || "");
  const [openAlert, setOpenAlert] = useState(false);
  const isCreate = type === "create";
  const buttonText = isCreate ? "Create Group" : "Edit Group";
  const { user } = useAuth0();
  const queryClient = useQueryClient();

  const { isLoading, mutate: createOrUpdateGroupMutation } = useMutation(
    async () => {
      if (user?.sub && competitionContext?.competition?.id) {
        return await createOrUpdateGroup({
          owner_auth0_id: user.sub,
          name,
          description,
          id: group?.id,
        });
      }
    },
    {
      onSuccess: () => {
        setOpen(false);
        setOpenAlert(true);
        queryClient.fetchQuery("get-leaderboard");
      },
    }
  );

  const clearState = () => {
    setNameError("");
    setName("");
    setDescription("");
  };

  const handleClose = () => {
    setOpen(false);
    if (isCreate) {
      clearState();
    }
  };

  const handleChangeTeam = (e: any) => {
    const name = e.target.value;
    setName(name);
    if (name) {
      setNameError("");
    }
  };

  const handleChangeDesc = (e: any) => {
    const desc = e.target.value;
    setDescription(desc);
  };

  const handleSubmit = async () => {
    if (!name) {
      setNameError("Please enter a name.");
      return;
    }
    createOrUpdateGroupMutation();
    clearState();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box> {isCreate ? "Create Group" : "Edit Group"}</Box>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            defaultValue={name}
            margin="dense"
            name="groupName"
            label="Group Name"
            type="text"
            onChange={(e) => handleChangeTeam(e)}
            fullWidth
          />
          <Typography color="error">{nameError}</Typography>
          <TextField
            variant="outlined"
            defaultValue={description}
            name="groupDescription"
            label="Group Description"
            type="text"
            placeholder="Add any other information you want your group to know."
            rows={3}
            multiline
            fullWidth
            onChange={(e) => handleChangeDesc(e)}
            style={{ marginTop: "8px" }}
            helperText="Optional"
          />
        </DialogContent>
        <DialogActions>
          <Box display="flex" width="100%" justifyContent="center" mb={1}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={isLoading}
            >
              {isLoading && (
                <CircularProgress
                  color="inherit"
                  className="spinner"
                  size="1rem"
                />
              )}
              {buttonText}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        content={"Your group has been saved successfully."}
        title="Group Saved"
        isLoading={false}
      />
    </div>
  );
};
export default GroupDialog;
