import React from "react";
import PageWrapper from "./PageWrapper";
import Groups from "../Groups";

const Teams = () => {
  return (
    <PageWrapper>
      <Groups />
    </PageWrapper>
  );
};

export default Teams;
