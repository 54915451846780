import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import LoginDialog from "./LoginDialog";
import { useAuth0 } from "@auth0/auth0-react";
import { getTeams } from "../services/fixture";
import { getUser } from "../services/user";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Team, User } from "../types";
import SkeletonFixture from "./SkeletonFixture";
import { saveWinningTeam } from "../services/user";
import AlertDialog from "./AlertDialog";

const PickWinner = ({
  isTournamentUpcoming,
}: {
  isTournamentUpcoming: boolean;
}) => {
  const { user } = useAuth0();
  const queryClient = useQueryClient();
  const [dbUser, setDbUser] = useState<null | User>(null);
  const [winningTeamId, setWinningTeamId] = useState<null | number>(null);
  const [openLogin, setOpenLogin] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [teams, setTeams] = useState<Team[]>([]);
  const winningCountry = teams.find(
    (team) => team.source_team_id === dbUser?.winner_source_team_id
  );

  const { isLoading, refetch: getTeamsQuery } = useQuery(
    "get-teams",
    async () => {
      return await getTeams();
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setTeams(data);
      },
    }
  );

  const { isLoading: isLoadingUser, refetch: getUserQuery } = useQuery(
    "get-user",
    async () => {
      if (user?.sub && user?.email) {
        return await getUser({ auth0_id: user.sub, email: user.email });
      }
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setDbUser(data);
      },
    }
  );

  const { isLoading: isMutationLoading, mutate: saveWinningTeamMutation } =
    useMutation(
      async () => {
        if (!user?.sub || !winningTeamId) return;
        return await saveWinningTeam({
          auth0_id: user.sub,
          is_winner_points_eligible: isTournamentUpcoming,
          winner_source_team_id: winningTeamId,
        });
      },
      {
        onSuccess: () => {
          setOpenAlert(true);
          queryClient.fetchQuery("get-user");
        },
      }
    );

  useEffect(() => {
    getTeamsQuery();
  }, [getTeamsQuery]);

  useEffect(() => {
    if (user?.sub) {
      getUserQuery();
    }
  }, [user, getUserQuery]);

  const handleClick = () => {
    if (user?.sub) {
      if (winningTeamId) {
        saveWinningTeamMutation();
      } else {
        return;
      }
    } else {
      setOpenLogin(true);
    }
  };

  const handleSelectChange = (e: any) => {
    setWinningTeamId(parseInt(e.target.value));
  };

  const loading = isLoading || isLoadingUser;

  return (
    <Box display="flex" justifyContent="center">
      <Box>
        <Box mb={2}>
          <Typography
            variant="h5"
            className="section-header"
          >{`pick the champion`}</Typography>
        </Box>
        {loading && (
          <Box mt={2}>
            <SkeletonFixture />
          </Box>
        )}
        {!loading && (
          <Box
            display="flex"
            className="fixture-box"
            justifyContent="space-between"
            flexDirection="column"
          >
            {!winningCountry && (
              <Box display="flex">
                <FormControl fullWidth style={{ padding: "15px" }}>
                  <Select onChange={(e: any) => handleSelectChange(e)}>
                    {teams.map((team) => (
                      <MenuItem
                        value={team.source_team_id}
                        key={team.source_team_id}
                      >
                        <Box>
                          <Box display="flex">
                            <Typography>{`${team.logo}`}</Typography>
                            <Typography>{`${team.name}`}</Typography>
                          </Box>
                          {team.fifa_ranking && (
                            <Typography className="ranking">
                              {`FIFA Ranking: ${team.fifa_ranking}`}
                            </Typography>
                          )}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box alignSelf="center" pr={0} mr={1} justifySelf="flex-end">
                  <Button
                    variant="contained"
                    onClick={handleClick}
                    disabled={isMutationLoading}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            )}
            {winningCountry && (
              <Box m={1} alignSelf="center">
                <Typography className="winner">{`🏆 Your Champion: ${winningCountry.logo} ${winningCountry.name}`}</Typography>
              </Box>
            )}
            <Box ml={1} mr={1} alignSelf="center">
              {!winningCountry && isTournamentUpcoming && (
                <Box mb={1} ml={1}>
                  <Typography
                    variant="body1"
                    className="predict-helper"
                  >{`Which team will win world cup 2023? Lock in your selection before the tournament starts & earn 250 bonus points if you're right. You cannot update this after saving.`}</Typography>
                </Box>
              )}
              {!winningCountry && !isTournamentUpcoming && (
                <Box mb={1} ml={1}>
                  <Typography
                    variant="body1"
                    className="predict-helper"
                  >{`Which team will win world cup 2023? You cannot update this after saving.`}</Typography>
                </Box>
              )}
              {winningCountry && dbUser?.is_winner_points_eligible && (
                <Box mb={1} ml={1} alignSelf="center">
                  <Typography
                    variant="body1"
                    className="predict-helper"
                  >{`You'll earn 250 bonus points if you're right. 🤞`}</Typography>
                </Box>
              )}
              {winningCountry && !dbUser?.is_winner_points_eligible && (
                <Box mb={1} ml={1} alignSelf="center">
                  <Typography
                    variant="body1"
                    className="predict-helper"
                  >{`Good luck 🤞`}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        )}

        <LoginDialog
          open={openLogin}
          setOpen={setOpenLogin}
          title="Pick the Champion"
          description="You must have an account in order to pick the champion."
        />
        <AlertDialog
          open={openAlert}
          setOpen={setOpenAlert}
          content={"Your champion has been saved successfully. Good luck 🤞"}
          title="Champion Saved"
          isLoading={false}
        />
      </Box>
    </Box>
  );
};

export default PickWinner;
