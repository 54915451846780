import React, { useEffect, useState, useContext } from "react";
import { Fixture } from "../types";
import { Typography, Box, Button } from "@mui/material";
import FixtureRow from "./FixtureRow";
import SkeletonFixture from "./SkeletonFixture";
import { getFixtures } from "../services/fixture";
import { getPredictions } from "../services/prediction";
import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { Prediction } from "../types";
import moment from "moment";
import PastSchedule from "./PastSchedule";
import LiveSchedule from "./LiveSchedule";
import { getMatchStatus } from "../constants";
import { UserContext } from "../context/UserContext";

const Schedule = () => {
  const [fixtures, setFixtures] = useState<Fixture[]>([]);
  const [predictions, setPredictions] = useState<Prediction[]>([]);
  const [showAll, setShowAll] = useState(false);
  const MAX_TO_SHOW = 5;
  const pastFixtures: Fixture[] = [];
  const liveFixtures: Fixture[] = [];
  const scheduledFixtures: Fixture[] = [];
  const { user, isLoading: userLoading } = useAuth0();
  const userContext = useContext(UserContext);

  const { isLoading: isLoadingPredictions, refetch: getPredictionsQuery } =
    useQuery(
      "get-predictions",
      async () => {
        if (user?.sub) {
          return await getPredictions({ auth0_id: user?.sub });
        }
      },
      {
        enabled: false,
        onSuccess: (data) => {
          data.sort((a: Prediction, b: Prediction) => {
            return moment(a.fixture.dt_start).diff(
              b.fixture.dt_start,
              "seconds"
            );
          });
          setPredictions(data);
        },
      }
    );

  const { isLoading, refetch: getFixturesQuery } = useQuery(
    "get-fixtures",
    async () => {
      return await getFixtures({
        auth0_id: user?.sub,
        competition_id: localStorage.getItem("last_competition_id"),
      });
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setFixtures(data);
      },
    }
  );

  useEffect(() => {
    if (!userLoading) {
      getFixturesQuery();
    }
  }, [getFixturesQuery, userLoading]);

  useEffect(() => {
    if (user?.sub) {
      getPredictionsQuery();
    }
  }, [user, getPredictionsQuery]);

  fixtures.forEach((fix) => {
    const currentStatus = getMatchStatus(fix.status_short);

    if (currentStatus === "completed") {
      pastFixtures.push(fix);
    } else if (currentStatus === "live") {
      liveFixtures.push(fix);
    } else {
      scheduledFixtures.push(fix);
    }
  });

  return (
    <>
      {liveFixtures.length > 0 ? (
        <LiveSchedule
          fixtures={liveFixtures}
          predictions={predictions}
          getFixtures={getFixturesQuery}
        />
      ) : (
        <></>
      )}
      <Box display="flex" justifyContent="center">
        <Box>
          <Box mb={2}>
            <Typography
              variant="h5"
              className="section-header"
            >{`match schedule`}</Typography>
          </Box>

          {userContext?.user && (
            <Box mb={2}>
              <Typography>{`Available Points: ${userContext?.user?.points}`}</Typography>
            </Box>
          )}

          {isLoading || isLoadingPredictions ? (
            <Box>
              <SkeletonFixture />
              <SkeletonFixture />
              <SkeletonFixture />
              <SkeletonFixture />
              <SkeletonFixture />
            </Box>
          ) : (
            <Box>
              {scheduledFixtures.map((fix, i) => {
                return showAll || i <= MAX_TO_SHOW ? (
                  <FixtureRow
                    fixture={fix}
                    key={fix.source_fixture_id}
                    predictions={predictions}
                  />
                ) : (
                  <></>
                );
              })}
            </Box>
          )}
          {scheduledFixtures.length > MAX_TO_SHOW && !showAll && (
            <Box width="100%" display="flex" justifyContent="center" mb={2}>
              <Button variant="contained" onClick={() => setShowAll(true)}>
                Show More
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {pastFixtures.length > 0 ? (
        <PastSchedule fixtures={pastFixtures} predictions={predictions} />
      ) : (
        <></>
      )}
    </>
  );
};

export default Schedule;
