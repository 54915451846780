import React, { useContext } from "react";
import PageWrapper from "../pages/PageWrapper";
import { Box, Typography } from "@mui/material";
import { CompetitionContext } from "../../context/CompetitionContext";

const About = () => {
  const competitionContext = useContext(CompetitionContext);
  return (
    <PageWrapper>
      <Box display="flex" justifyContent="center">
        <Box className="sizing">
          <Box mb={2}>
            <Typography
              variant="h5"
              className="section-header"
            >{`about`}</Typography>
            <Box mt={1}>
              <Typography>{`worldie wide web is a place for women's football/soccer`}</Typography>
            </Box>
          </Box>

          <Box mb={2}>
            <Typography variant="h5" className="section-header">{`${
              competitionContext?.competition?.name || ""
            }`}</Typography>
            <Box mt={1}>
              <Typography>{`${
                competitionContext?.competition?.description || ""
              }`}</Typography>
            </Box>
          </Box>
          <Box mb={2}>
            <Typography
              variant="h5"
              className="section-header"
            >{`points`}</Typography>
            <Box mt={1}>
              <ul>
                <li>
                  {`Everyone starts out with 500 points to be used throughout the
                  ${competitionContext?.competition?.num_matches || ""} matches
                  of the competition`}
                </li>
                <li>
                  Predictions can be set for as many or few matches as you like
                </li>
                <li>
                  You may update or cancel your prediction up until the match
                  starts
                </li>
                <li>Winning predictions double your points bet</li>
                <li>
                  Predictions will be paid out upon completion of each match
                </li>
                <li>
                  These points are just for fun and have no value, monetery or
                  otherwise
                </li>
              </ul>
            </Box>
          </Box>

          <Box mb={2}>
            <Typography
              variant="h5"
              className="section-header"
            >{`contact`}</Typography>
            <Box mt={1}>
              <Typography>{`support@worldiewideweb.com`}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default About;
