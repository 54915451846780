import React, { useState, useEffect, useContext } from "react";
import { getCompetitions, switchCompetition } from "../services/competition";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Competition } from "../types";
import {
  Typography,
  Box,
  FormControl,
  MenuItem,
  Select,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Button,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { CompetitionContext } from "../context/CompetitionContext";

type Props = {
  currentCompId: number;
  auth0Id: string | null | undefined;
  isOpen: boolean;
  handleClose: () => void;
};

const SwitchCompetitionDialog = ({
  currentCompId,
  auth0Id,
  isOpen,
  handleClose,
}: Props) => {
  const competitionContext = useContext(CompetitionContext);
  const queryClient = useQueryClient();
  const [comps, setComps] = useState<null | Competition[]>(null);
  const [compId, setCompId] = useState(currentCompId);
  const { refetch: getCompetitionsQuery } = useQuery(
    "get-competitions",
    async () => {
      return await getCompetitions();
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setComps(data);
      },
    }
  );
  useEffect(() => {
    getCompetitionsQuery();
  }, [getCompetitionsQuery]);

  const { isLoading: isMutationLoading, mutate: switchCompetitionMutation } =
    useMutation(
      async () => {
        return await switchCompetition({
          auth0_id: auth0Id,
          competition_id: compId,
        });
      },
      {
        onSuccess: async (data) => {
          handleClose();
          competitionContext?.setCompetition(data);
          if (auth0Id) {
            queryClient.fetchQuery("get-user");
            queryClient.fetchQuery("get-invites");
            queryClient.fetchQuery("get-predictions");
          }
          queryClient.fetchQuery("get-fixtures");
          queryClient.fetchQuery("get-table");
          queryClient.fetchQuery("get-leaderboard");
          queryClient.fetchQuery("get-kit-log");
          queryClient.fetchQuery("get-kit-log-summary");
        },
      }
    );

  const handleChange = (e: any) => {
    setCompId(e.target.value);
  };

  const handleSubmit = () => {
    localStorage.setItem("last_competition_id", `${compId}`);
    switchCompetitionMutation();
  };

  if (!comps) {
    return <></>;
  }

  return (
    <Dialog open={isOpen} onClose={() => null}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box> Switch Competition </Box>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth style={{ padding: "15px" }}>
          <Select
            onChange={(e: any) => handleChange(e)}
            defaultValue={currentCompId}
            title="Switch Competition"
          >
            {comps.map((comp) => (
              <MenuItem value={comp.id} key={comp.id}>
                <Box>
                  <Box display="flex">
                    <Typography>{`${comp.name}`}</Typography>
                  </Box>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Box m={1}>
            <Button onClick={handleClose} variant="outlined">
              Close
            </Button>
          </Box>

          <Box m={1}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={isMutationLoading}
            >
              {isMutationLoading && (
                <CircularProgress
                  color="inherit"
                  className="spinner"
                  size="1rem"
                />
              )}
              Switch
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SwitchCompetitionDialog;
