import React, { useState } from "react";
import { Competition } from "../types";

type CompetitionContextProviderProps = {
  children: React.ReactNode;
};

type CompetitionContextType = {
  competition: Competition | null;
  setCompetition: React.Dispatch<React.SetStateAction<Competition | null>>;
};

export const CompetitionContext =
  React.createContext<CompetitionContextType | null>(null);

export const CompetitionContextProvider = ({
  children,
}: CompetitionContextProviderProps) => {
  const [competition, setCompetition] = useState<Competition | null>(null);
  return (
    <CompetitionContext.Provider value={{ competition, setCompetition }}>
      {children}
    </CompetitionContext.Provider>
  );
};
