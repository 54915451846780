import React, { useState } from "react";
import { Fixture, Prediction } from "../types";
import { formatLocalDate, getRoundText } from "../services/util";
import { Typography, Box, Chip, Button } from "@mui/material";
import PredictDialog from "./PredictDialog";
import LoginDialog from "./LoginDialog";
import { useAuth0 } from "@auth0/auth0-react";
import { hoursUntilDate } from "../services/util";
import Logo from "./Logo";

type Props = {
  fixture: Fixture;
  predictions: Prediction[];
};

const FixtureRow = ({ fixture, predictions }: Props) => {
  const { user } = useAuth0();
  const { dt_start, home_team, away_team, round } = fixture;
  const { hasStarted } = hoursUntilDate(dt_start);
  const [open, setOpen] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const isGroupStage = round.includes("Group") || round.includes("Regular");
  const roundText = getRoundText(round, home_team.group);
  const existingPrediction = predictions.find(
    (p) => p.source_fixture_id === fixture.source_fixture_id
  );
  const buttonText = existingPrediction ? "Update" : "Predict";
  let predictionText;

  if (existingPrediction) {
    if (
      existingPrediction.winner_source_team_id ===
      existingPrediction.fixture.home_team_id
    ) {
      predictionText = `You predict ${existingPrediction.fixture.home_team.name} will win.`;
    } else if (
      existingPrediction.winner_source_team_id ===
      existingPrediction.fixture.away_team_id
    ) {
      predictionText = `You predict ${existingPrediction.fixture.away_team.name} will win.`;
    } else {
      predictionText = `You predict a draw.`;
    }
  }

  const handleClickOpen = () => {
    if (user?.sub) {
      setOpen(true);
    } else {
      setOpenLogin(true);
    }
  };

  return (
    <Box className="fixture-box" display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between">
        <Box
          display="flex"
          flexDirection="column"
          p={1}
          alignItems="flex-start"
        >
          <Box display="flex">
            <Chip
              size="small"
              label={roundText}
              className="group-chip"
              style={{ backgroundColor: "#fff689ff" }}
            />
          </Box>

          <Typography className="fixture-text">{`${formatLocalDate(
            dt_start
          )}`}</Typography>
          <Box display="flex">
            <Box>
              <Typography width="25px">
                <Logo
                  logo={home_team.logo}
                  logo_url={home_team.logo_url}
                  name={home_team.name}
                />
              </Typography>
            </Box>
            <Typography>{`${home_team.name}`}</Typography>
          </Box>
          <Box display="flex">
            <Box>
              <Typography width="25px">
                <Logo
                  logo={away_team.logo}
                  logo_url={away_team.logo_url}
                  name={away_team.name}
                />
              </Typography>
            </Box>
            <Typography>{`${away_team.name}`}</Typography>
          </Box>
        </Box>

        <Box alignSelf="center" pr={0} mr={1} justifySelf="flex-end">
          <Button
            variant="contained"
            onClick={handleClickOpen}
            className={
              existingPrediction
                ? "predict-button predict-button-gray"
                : "predict-button "
            }
            style={{ visibility: hasStarted ? "hidden" : "visible" }}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
      {existingPrediction && (
        <Box m={1} display="flex">
          <Typography style={{ fontSize: "13px" }}>
            <Chip
              size="small"
              label={`${existingPrediction?.points_bet} points`}
              className="group-chip"
              style={{ backgroundColor: "#a0a4b8ff" }}
            />
            {predictionText}
          </Typography>
        </Box>
      )}
      <PredictDialog
        open={open}
        setOpen={setOpen}
        fixture={fixture}
        roundText={roundText}
        isGroupStage={isGroupStage}
        type={existingPrediction ? "update" : "create"}
        prediction={existingPrediction}
      />

      <LoginDialog
        open={openLogin}
        setOpen={setOpenLogin}
        title="Predict the Winner"
        description="You must have an account in order to set predictions."
      />
    </Box>
  );
};

export default FixtureRow;
