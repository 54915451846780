import React from "react";
import { Typography } from "@mui/material";

type Props = {
  logo: string;
  logo_url: string;
  name: string;
};

const Logo = ({ logo, logo_url, name }: Props) => {
  if (logo) {
    return <Typography>{logo}</Typography>;
  } else if (logo_url) {
    return <img src={logo_url} alt={name} height={18} />;
  } else {
    return <></>;
  }
};

export default Logo;
