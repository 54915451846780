import React, { useState } from "react";
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import { createOrUpdateInvite } from "../services/user";
import { useMutation } from "react-query";
import AlertDialog from "./AlertDialog";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../services/util";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const InviteDialog = ({ open, setOpen }: Props) => {
  const { user } = useAuth0();
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const navigate = useNavigate();

  const { isLoading, mutate: createInviteMuation } = useMutation(
    async () => {
      if (!user?.sub) return;
      return await createOrUpdateInvite({
        inviter_auth0_id: user.sub,
        email,
        status: "pending",
      });
    },
    {
      onSuccess: () => {},
    }
  );

  const handleClose = () => {
    setOpen(false);
  };

  const onEmailChangeHandler = (e: any) => {
    if (
      validateEmail(e.target.value) &&
      user?.email &&
      user.email !== e.target.value
    ) {
      setEmailError("");
    }
    setEmail(e.target.value.toLowerCase());
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email");
      return;
    }
    if (email && user?.email && email === user?.email) {
      setEmailError("No need to invite yourself. You're already #1 here.");
      return;
    }
    if (!user?.sub) return;
    createInviteMuation();
    setOpen(false);
    setOpenAlert(true);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box> Invite Friend </Box>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Invite a friend to join your leaderboard. You'll each be able to see
            other's points for some friendly competition.
          </Typography>
          <TextField
            variant="outlined"
            margin="dense"
            id="email"
            label="Email to invite"
            type="email"
            onChange={(e) => onEmailChangeHandler(e)}
            fullWidth
          />
          <Typography color="error">{emailError}</Typography>
        </DialogContent>
        <DialogActions>
          <Box display="flex" width="100%" justifyContent="center" mb={1}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={isLoading}
            >
              {isLoading && (
                <CircularProgress
                  color="inherit"
                  className="spinner"
                  size="1rem"
                />
              )}
              {`Invite`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        buttonText={"notify friend"}
        content={`${email} has been invited to join your leaderboard. They can accept your invitation on the app. Remind them to visit this site.`}
        buttonClickHandler={() => navigate("/predictions")}
        title="Invite Sent"
        isLoading={false}
        href={`sms:?&body=Join%20my%20world%20cup%202023%20leaderboard%20on%20worldiewideweb.com`}
      />
    </div>
  );
};
export default InviteDialog;
