import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useQuery } from "react-query";
import SkeletonFixture from "./SkeletonFixture";
import InviteDialog from "./InviteDialog";
import { getInvites, getLeaderboard } from "../services/user";
import { useAuth0 } from "@auth0/auth0-react";
import { Invite, Leaderboard, GroupMember, UserDetails } from "../types";
import LoginDialog from "./LoginDialog";
import ViewInvitesDialog from "./ViewInvitesDialog";
import MyGroups from "./MyGroups";
import GroupUserRow from "./GroupUserRow";

const MyLeaderboard = () => {
  const [invites, setInvites] = useState<Invite[]>([]);
  const [openInvite, setOpenInvite] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openViewInvites, setOpenViewInvites] = useState(false);
  const [leaderboard, setLeaderboard] = useState<Leaderboard[]>([]);
  const [usersDetails, setUsersDetails] = useState<UserDetails[]>([]);
  const [globalLeaderboard, setGlobalLeaderboard] = useState<Leaderboard[]>([]);
  const [myGroups, setMyGroups] = useState<GroupMember[]>([]);
  const { user, isLoading } = useAuth0();
  const { isLoading: isLoadingInvites, refetch: getInvitesQuery } = useQuery(
    "get-invites",
    async () => {
      if (user?.sub && user?.email) {
        return await getInvites({ auth0_id: user?.sub, email: user?.email });
      }
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setInvites(data);
      },
    }
  );

  const { isLoading: isLeaderboardLoading, refetch: getLeaderboardQuery } =
    useQuery(
      "get-leaderboard",
      async () => {
        return await getLeaderboard({
          auth0_id: user?.sub,
          competition_id: localStorage.getItem("last_competition_id"),
        });
      },
      {
        enabled: false,
        onSuccess: (data) => {
          const { myLeaderboard, globalLeaderboard, myGroups, usersDetails } =
            data;
          setLeaderboard(myLeaderboard);
          setGlobalLeaderboard(globalLeaderboard);
          setMyGroups(myGroups);
          setUsersDetails(usersDetails);
        },
      }
    );

  useEffect(() => {
    if (user?.sub) {
      getInvitesQuery();
    }
  }, [getInvitesQuery, user]);

  useEffect(() => {
    if (!isLoading) {
      getLeaderboardQuery();
    }
  }, [getLeaderboardQuery, isLoading]);

  const onClickHandler = () => {
    if (user) {
      setOpenInvite(true);
    } else {
      setOpenLogin(true);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box>
          <MyGroups groupMembers={myGroups} usersDetails={usersDetails} />
          <Box mb={2}>
            <Typography
              variant="h5"
              className="section-header"
            >{`my leaderboard`}</Typography>
            <Box mt={1}>
              <Typography>{`Compare points with your friends.`}</Typography>
            </Box>
            <Box display="flex">
              <Button variant="contained" onClick={onClickHandler}>
                Invite Friend
              </Button>
              {invites?.length > 0 && (
                <Box ml={2}>
                  <Button
                    variant="contained"
                    onClick={() => setOpenViewInvites(true)}
                  >
                    <Box mr={1}>
                      <Chip
                        label={invites?.length}
                        color="error"
                        size="small"
                      />{" "}
                    </Box>
                    View Invites
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Box className="disclaimer-box"></Box>
          {isLeaderboardLoading && (
            <Box>
              <SkeletonFixture />
              <SkeletonFixture />
              <SkeletonFixture />
              <SkeletonFixture />
              <SkeletonFixture />
            </Box>
          )}
          {!isLeaderboardLoading && leaderboard.length > 0 && (
            <TableContainer component={Paper} className="leaderboard-container">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Points in Bets</TableCell>
                    <TableCell align="right">Total Points</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaderboard.map((row, i) => {
                    const userDetails = usersDetails.find(
                      (u) => u.auth0_id === row.auth0_id
                    );
                    return (
                      <GroupUserRow
                        row={row}
                        i={i}
                        isOwner={false}
                        isSameUser={false}
                        userDetails={userDetails}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!isLeaderboardLoading && globalLeaderboard.length > 0 && (
            <>
              <Box mt={3} mb={2}>
                <Typography
                  variant="h5"
                  className="section-header"
                >{`global leaderboard`}</Typography>
              </Box>
              <TableContainer
                component={Paper}
                className="leaderboard-container"
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Points in Bets</TableCell>
                      <TableCell align="right">Total Points</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {globalLeaderboard.map((row, i) => {
                      const userDetails = usersDetails.find(
                        (u) => u.auth0_id === row.auth0_id
                      );
                      return (
                        <GroupUserRow
                          row={row}
                          i={i}
                          isOwner={false}
                          isSameUser={false}
                          userDetails={userDetails}
                          isGlobal
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          <InviteDialog open={openInvite} setOpen={setOpenInvite} />
          {!isLoadingInvites && (
            <ViewInvitesDialog
              invites={invites}
              open={openViewInvites}
              setOpen={setOpenViewInvites}
            />
          )}
        </Box>
      </Box>
      <LoginDialog
        open={openLogin}
        setOpen={setOpenLogin}
        title="Invite Friend"
        description="You must have an account in order to invite a friend."
      />
    </>
  );
};

export default MyLeaderboard;
