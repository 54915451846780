import React from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

type Props = {
  product: any;
};

const ProductRow = ({ product }: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      width={350}
      mb={2}
    >
      <Typography className="product-title">{product.fields.title}</Typography>
      <img
        src={product.fields.image.fields.file.url}
        alt={product.fields.title}
        width={350}
      />

      <Box mt={1}>
        <Typography className="product-subtitle">
          {product.fields.subtitle}
        </Typography>
      </Box>
      <Box justifySelf="flex-end" mt={1}>
        <Button variant="contained" href={product.fields.url} target="_blank">
          {`Buy on ${product.fields.siteName}`}
          <LaunchIcon className="launch-icon" />
        </Button>
      </Box>
      <Box mt={2}>
        <Divider />
      </Box>
    </Box>
  );
};

export default ProductRow;
