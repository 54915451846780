import React from "react";
import MyLeaderboard from "../MyLeaderboard";
import PageWrapper from "../pages/PageWrapper";

const Matches = () => {
  return (
    <PageWrapper>
      <MyLeaderboard />
    </PageWrapper>
  );
};

export default Matches;
