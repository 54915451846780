import React, { useState } from "react";
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import { createGroupMember } from "../services/group";
import { useMutation, useQueryClient } from "react-query";
import AlertDialog from "./AlertDialog";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  urlCode?: string | null;
};

const JoinGroupDialog = ({ open, setOpen, urlCode }: Props) => {
  const { user } = useAuth0();
  const [codeError, setCodeError] = useState("");
  const [code, setCode] = useState(urlCode || "");
  const [openAlert, setOpenAlert] = useState(false);
  const [response, setResponse] = useState("");
  const queryClient = useQueryClient();

  const { isLoading, mutate: createMemberMutation } = useMutation(
    async () => {
      if (!user?.sub) return;
      return await createGroupMember({
        auth0_id: user.sub,
        code,
        is_owner: false,
      });
    },
    {
      onSuccess: async (data) => {
        queryClient.fetchQuery("get-leaderboard");
        setResponse(
          `You been successfully added to the group ${data.name} for competition ${data.competition.name}`
        );
        setOpen(false);
        setOpenAlert(true);
        clearState();
      },
      onError: async () => {
        setResponse(`${code} is not a valid code. Try again.`);
        setOpen(false);
        setOpenAlert(true);
        clearState();
      },
    }
  );

  const clearState = () => {
    setCode("");
    setCodeError("");
  };

  const handleClose = () => {
    setOpen(false);
    clearState();
  };

  const onCodeChangeHandler = (e: any) => {
    setCode(e.target.value.toLowerCase());
  };

  const handleSubmit = async () => {
    if (code.length !== 5) {
      setCodeError("Please enter a 5 letter code");
      return;
    }

    if (!user?.sub) return;
    createMemberMutation();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box> Join Group </Box>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>Enter 5 letter code to join group.</Typography>
          <TextField
            variant="outlined"
            margin="dense"
            id="email"
            label="Code"
            type="email"
            onChange={(e) => onCodeChangeHandler(e)}
            fullWidth
            value={code}
          />
          <Typography color="error">{codeError}</Typography>
        </DialogContent>
        <DialogActions>
          <Box display="flex" width="100%" justifyContent="center" mb={1}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={isLoading}
            >
              {isLoading && (
                <CircularProgress
                  color="inherit"
                  className="spinner"
                  size="1rem"
                />
              )}
              {`Join Group`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        content={`${response}`}
        title="Join Group"
        isLoading={false}
      />
    </div>
  );
};
export default JoinGroupDialog;
