import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Invite } from "../types";
import InviteRow from "./InviteRow";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  invites: Invite[];
};

const ViewInvitesDialog = ({ open, setOpen, invites }: Props) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box> View Invites </Box>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {invites.map((i) => {
            return <InviteRow invite={i} key={i.id} />;
          })}
          {invites.length === 0 && <Typography>No pending invites</Typography>}
        </DialogContent>
        <DialogActions>
          <Box display="flex" width="100%" justifyContent="center" mb={1}>
            <Button onClick={handleClose} variant="contained">
              {`Close`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ViewInvitesDialog;
