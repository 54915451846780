import React from "react";
import { Skeleton } from "@mui/material";

type Props = {
  animation?: false | "pulse" | "wave" | undefined;
};

const SkeletonFixture = ({ animation }: Props) => {
  return (
    <Skeleton
      variant="rounded"
      height={80}
      width={350}
      sx={{ bgcolor: "grey.900" }}
      className="skeleton-fixture"
      animation={animation}
    />
  );
};

export default SkeletonFixture;
