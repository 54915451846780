import React, { useState } from "react";
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
// import { createOrUpdateInvite } from "../services/user";
// import { useMutation } from "react-query";
import AlertDialog from "./AlertDialog";
import { validateEmail } from "../services/util";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Group } from "../types";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  code: string | null;
  group: Group;
};

const InviteMemberDialog = ({ open, setOpen, code, group }: Props) => {
  const showEmailField = false;
  const { user } = useAuth0();
  const [emailError, setEmailError] = useState("");
  const [emails, setEmails] = useState<string[]>([]);
  const [openAlert, setOpenAlert] = useState(false);

  //   const { isLoading, mutate: createInviteMuation } = useMutation(
  //     async () => {
  //       if (!user?.sub) return;
  //       return await createOrUpdateInvite({
  //         inviter_auth0_id: user.sub,
  //         email,
  //         status: "pending",
  //       });
  //     },
  //     {
  //       onSuccess: () => {},
  //     }
  //   );

  const handleClose = () => {
    setOpen(false);
  };

  const onEmailChangeHandler = (e: any) => {
    const emailArray = e.target.value
      ? e.target.value.split(",").map((ele: string) => ele.trim().toLowerCase())
      : [];

    setEmails(emailArray);
  };

  const copyCode = () => {
    navigator.clipboard.writeText(code || "");
  };

  const copyUrl = () => {
    const url = code
      ? `${window.location.origin}/leaderboard?code=${code}`
      : "";
    navigator.clipboard.writeText(url);
  };

  const handleSubmit = async () => {
    for (let index = 0; index < emails.length; index++) {
      const em = emails[index];
      if (!validateEmail(em)) {
        setEmailError(`${em} is not a valid email`);
        return;
      }
    }

    if (!user?.sub || emails.length === 0) return;
    // createInviteMuation();
    setOpen(false);
    setOpenAlert(true);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box> Invite Friends </Box>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>
            {`Share this code with friends to join group: ${group.name}`}
          </Typography>
          <Box
            display="flex"
            bgcolor="#fff689ff"
            borderRadius="6px"
            p={2}
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <Typography variant="h6">{code}</Typography>
            <Button onClick={copyCode}>
              <ContentCopyIcon />
            </Button>
          </Box>
          {showEmailField && (
            <>
              <Box display="flex" justifyContent="center" m={2}>
                <Typography variant="h5" style={{ fontWeight: "600" }}>
                  OR
                </Typography>
              </Box>

              <Typography>Invite friends to join via email.</Typography>

              <TextField
                variant="outlined"
                margin="dense"
                id="email"
                label="Email(s) to invite"
                type="email"
                onChange={(e) => onEmailChangeHandler(e)}
                fullWidth
                helperText={"Comma-separated list of emails"}
              />
              <Typography color="error">{emailError}</Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Box display="flex" width="100%" justifyContent="center" mb={1}>
            <Button onClick={copyUrl} variant="contained">
              {`Copy Share Url`}
            </Button>
            {showEmailField && (
              <Button
                onClick={handleSubmit}
                variant="contained"
                //   disabled={isLoading}
              >
                {/* {isLoading && (
                <CircularProgress
                  color="inherit"
                  className="spinner"
                  size="1rem"
                />
              )} */}
                {`Invite`}
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        content={`Email(s) have been invited to join your group. They can accept your invitation on worldiewideweb.com`}
        title="Invites Sent"
        isLoading={false}
      />
    </div>
  );
};
export default InviteMemberDialog;
