import React, { useState } from "react";
import { Fixture, Prediction } from "../types";
import { formatLocalDate, getRoundText } from "../services/util";
import { Typography, Box, Chip, Grid } from "@mui/material";
import { statusMapping } from "../constants";
import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { hoursUntilDate } from "../services/util";
import Logo from "./Logo";
import GoalEvents from "./GoalEvents";

type Props = {
  fixture: Fixture;
  prediction?: Prediction;
  isLive?: boolean;
  refreshHandler?: () => void;
};

const PastFixtureRow = ({
  fixture,
  prediction,
  isLive,
  refreshHandler,
}: Props) => {
  const { dt_start, home_team, away_team, round, elapsed, status_short } =
    fixture;
  const { isMoreThan48HoursFinished } = hoursUntilDate(dt_start);
  const [hide, setHide] = useState(
    isLive || !isMoreThan48HoursFinished ? true : false
  );
  const roundText = getRoundText(round, home_team.group);
  let pointDifference;
  let predictionText;

  const predictWord = isLive ? "predict" : "predicted";
  const willWord = isLive ? "will" : "would";

  if (prediction) {
    pointDifference =
      prediction.is_winner === true
        ? `+${prediction.points_bet}`
        : `-${prediction.points_bet}`;

    if (prediction.winner_source_team_id === prediction.fixture.home_team_id) {
      predictionText = `You ${predictWord} ${prediction.fixture.home_team.name} ${willWord} win.`;
    } else if (
      prediction.winner_source_team_id === prediction.fixture.away_team_id
    ) {
      predictionText = `You ${predictWord} ${prediction.fixture.away_team.name} ${willWord} win.`;
    } else {
      predictionText = `You ${predictWord} a draw.`;
    }
  }

  const revealHandler = () => {
    if (refreshHandler) refreshHandler();
    setHide(false);
  };

  return (
    <Box display="flex" className="fixture-box" justifyContent="space-between">
      <Box
        display="flex"
        flexDirection="column"
        p={1}
        alignItems="flex-start"
        width="100%"
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex">
            <Chip
              size="small"
              label={roundText}
              className="group-chip"
              style={{ backgroundColor: "#fff689ff" }}
            />

            {!isLive && (
              <Typography className="fixture-text">{`${
                formatLocalDate(dt_start).split(",")[1]
              }`}</Typography>
            )}
          </Box>

          {isLive && elapsed !== null && (
            <Box display="flex">
              {hide ? (
                <Chip
                  clickable
                  icon={<VisibilityIcon />}
                  onClick={revealHandler}
                  size="small"
                  label={`Reveal Score`}
                  className="group-chip"
                  style={{ backgroundColor: "#a0a4b8ff", marginRight: "5px" }}
                />
              ) : (
                <Chip
                  clickable
                  icon={<RefreshIcon />}
                  onClick={refreshHandler ? refreshHandler : undefined}
                  size="small"
                  label={`Refresh Score`}
                  className="group-chip"
                  style={{ backgroundColor: "#a0a4b8ff", marginRight: "5px" }}
                />
              )}
              <Chip
                size="small"
                label={status_short === "HT" ? `HT ${elapsed}'` : `${elapsed}'`}
                className="group-chip"
                style={{ backgroundColor: "#7ae7c7ff" }}
              />
            </Box>
          )}
        </Box>

        <Grid container justifyContent={"space-between"}>
          <Grid item xs={5} alignSelf="flex-start">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography className="past-fixture">
                <Logo
                  logo={home_team.logo}
                  logo_url={home_team.logo_url}
                  name={home_team.name}
                />
              </Typography>
              <Typography
                className="past-fixture"
                width={"40px"}
              >{`${home_team.name} `}</Typography>
              {!hide && (
                <GoalEvents
                  fixture={fixture}
                  source_team_id={home_team.source_team_id}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              {hide ? (
                <Box
                  onClick={() => setHide(false)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography className="past-fixture-score hidden-text">{`0 - 0`}</Typography>
                </Box>
              ) : (
                <Box>
                  <Typography className="past-fixture-score">{`${
                    fixture.goals_home || 0
                  }  - ${fixture.goals_away || 0}`}</Typography>
                  {fixture.penalty_away && (
                    <Typography>{`(${fixture.penalty_home || 0}  - ${
                      fixture.penalty_away || 0
                    })`}</Typography>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={5} justifySelf="flex-end">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography className="past-fixture">
                <Logo
                  logo={away_team.logo}
                  logo_url={away_team.logo_url}
                  name={away_team.name}
                />
              </Typography>
              <Typography className="past-fixture">{`${away_team.name} `}</Typography>
              {!hide && (
                <GoalEvents
                  fixture={fixture}
                  source_team_id={away_team.source_team_id}
                />
              )}
            </Box>
          </Grid>
        </Grid>
        {prediction && (
          <Box mt={2} display="flex">
            <Typography style={{ fontSize: "13px" }}>
              <Chip
                size="small"
                label={
                  isLive
                    ? `${prediction.points_bet} points`
                    : `${statusMapping[prediction.status]?.label} ${
                        prediction.status.includes("settled")
                          ? pointDifference
                          : ""
                      }` || ""
                }
                className="group-chip"
                style={{
                  backgroundColor: `${
                    statusMapping[prediction.status]?.color || undefined
                  }`,
                }}
              />
              {predictionText}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PastFixtureRow;
