import React, { useState, useContext, useEffect } from "react";
import { Fixture, Prediction } from "../types";
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Chip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { formatLocalDate } from "../services/util";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import { createOrUpdatePrediction } from "../services/prediction";
import { UserContext } from "../context/UserContext";
import { useMutation, useQueryClient } from "react-query";
import AlertDialog from "./AlertDialog";
import { useNavigate } from "react-router-dom";
import { pointsDisclaimer } from "../constants";
import Logo from "./Logo";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fixture: Fixture;
  roundText: string;
  isGroupStage: boolean;
  type: "create" | "update";
  prediction?: Prediction | null;
};

const PredictDialog = ({
  open,
  setOpen,
  fixture,
  roundText,
  isGroupStage,
  type,
  prediction,
}: Props) => {
  const isPredictionPage = window.location.pathname === "/predictions";
  const { home_team, away_team, dt_start } = fixture;
  const { user } = useAuth0();
  const [winningTeamId, setWinningTeamId] = useState<number | null>(
    prediction?.winner_source_team_id
      ? parseInt(prediction?.winner_source_team_id)
      : null
  );
  const [pointsBet, setPointsBet] = useState<number | null>(
    prediction?.points_bet || null
  );
  const [teamError, setTeamError] = useState("");
  const [pointsError, setPointsError] = useState(false);
  const [availablePoints, setAvailablePoints] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  const userContext = useContext(UserContext);
  const queryClient = useQueryClient();
  const isCreate = type === "create";
  const buttonText = isCreate ? "Place My Bet" : "Update My Bet";
  const navigate = useNavigate();

  const { isLoading, mutate: createPredictionMutation } = useMutation(
    async () => {
      if (!user?.sub || !winningTeamId || pointsBet === null) return;
      const fixtureId = parseInt(fixture.source_fixture_id.toString());
      return await createOrUpdatePrediction({
        auth0_id: user.sub,
        source_fixture_id: fixtureId,
        winner_source_team_id: winningTeamId,
        points_bet: pointsBet,
        id: prediction?.id || undefined,
      });
    },
    {
      onSuccess: () => {
        setOpen(false);
        setOpenAlert(true);
        queryClient.fetchQuery("get-user");
        queryClient.fetchQuery("get-predictions");
      },
    }
  );

  useEffect(() => {
    if (userContext?.user) {
      if (prediction?.points_bet && !isCreate) {
        setAvailablePoints(userContext?.user.points + prediction?.points_bet);
      } else {
        setAvailablePoints(userContext?.user.points);
      }
    }
  }, [userContext, prediction?.points_bet, isCreate]);

  const clearState = () => {
    setPointsError(false);
    setTeamError("");
  };

  const handleClose = () => {
    setOpen(false);
    clearState();
  };

  const onPointsChangeHandler = (e: any) => {
    const num = parseInt(e.target.value);
    if (num && num >= 0 && num <= availablePoints) {
      setPointsError(false);
    }
    setPointsBet(num);
  };

  const onTeamChangeHandler = (e: any) => {
    const team = parseInt(e.target.value);
    if (team) {
      setTeamError("");
    }
    setWinningTeamId(team);
  };

  const handleSubmit = async () => {
    if (!winningTeamId) {
      setTeamError("Please select one");
      return;
    }
    if (
      pointsBet === null ||
      isNaN(pointsBet) ||
      pointsBet < 0 ||
      pointsBet > availablePoints
    ) {
      setPointsError(true);
      return;
    }
    if (!user?.sub) return;
    clearState();
    createPredictionMutation();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box> Predict the Winner </Box>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Chip
            size="small"
            label={roundText}
            className="group-chip"
            style={{ backgroundColor: "#fff689ff" }}
          />
          <Typography className="fixture-text">{`${formatLocalDate(
            dt_start
          )}`}</Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={prediction?.winner_source_team_id || undefined}
            name="radio-buttons-group"
            onChange={(e) => onTeamChangeHandler(e)}
          >
            <FormControlLabel
              value={home_team.source_team_id}
              control={<Radio />}
              label={
                <Box>
                  <Box display="flex">
                    <Typography width="25px">
                      <Logo
                        logo={home_team.logo}
                        logo_url={home_team.logo_url}
                        name={home_team.name}
                      />
                    </Typography>
                    <Typography>{`${home_team.name}`}</Typography>
                  </Box>
                  {home_team.fifa_ranking && (
                    <Typography className="ranking">
                      {`FIFA Ranking: ${home_team.fifa_ranking}`}
                    </Typography>
                  )}
                </Box>
              }
            />
            <FormControlLabel
              value={away_team.source_team_id}
              control={<Radio />}
              label={
                <Box>
                  <Box display="flex">
                    <Typography width="25px">
                      <Logo
                        logo={away_team.logo}
                        logo_url={away_team.logo_url}
                        name={away_team.name}
                      />
                    </Typography>
                    <Typography>{`${away_team.name}`}</Typography>
                  </Box>
                  {away_team.fifa_ranking && (
                    <Typography className="ranking">
                      {`FIFA Ranking: ${away_team.fifa_ranking}`}
                    </Typography>
                  )}
                </Box>
              }
            />
            {isGroupStage && (
              <FormControlLabel
                value={1}
                control={<Radio />}
                label={
                  <Box>
                    <Typography>{`🤝 Draw`}</Typography>
                    {away_team.fifa_ranking && (
                      <Typography className="ranking">
                        {`group stage only`}
                      </Typography>
                    )}
                  </Box>
                }
              />
            )}
          </RadioGroup>
          <Typography color="error">{teamError}</Typography>

          <TextField
            variant="outlined"
            defaultValue={prediction?.points_bet || undefined}
            margin="dense"
            id="name"
            label="Points to Bet"
            type="number"
            helperText={
              <Typography
                color={pointsError ? "error" : undefined}
                style={{ fontSize: "12px" }}
              >
                {`Enter a whole number between 0 and ${availablePoints}`}
              </Typography>
            }
            InputProps={{
              inputProps: { min: 0, max: availablePoints, step: "1" },
            }}
            onChange={(e) => onPointsChangeHandler(e)}
          />
          <Typography>{`Available Points: ${availablePoints}`}</Typography>
        </DialogContent>
        <DialogActions>
          <Box>
            <Box display="flex" width="100%" justifyContent="center">
              <Button
                onClick={handleSubmit}
                variant="contained"
                disabled={isLoading}
              >
                {isLoading && (
                  <CircularProgress
                    color="inherit"
                    className="spinner"
                    size="1rem"
                  />
                )}
                {buttonText}
              </Button>
            </Box>
            <Box m={2}>
              <Typography className="predict-helper">
                {pointsDisclaimer}
              </Typography>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        buttonText={!isPredictionPage ? "view predictions" : undefined}
        content={"Your prediction has been saved successfully. Good luck 🤞"}
        buttonClickHandler={
          !isPredictionPage ? () => navigate("/predictions") : undefined
        }
        title="Prediction Saved"
        isLoading={false}
      />
    </div>
  );
};
export default PredictDialog;
