import axios from "axios";
import { headers } from "./";

export interface KitLogInput {
  name: string;
  competition_id: number;
  color: string | null | undefined;
  logo: string | null | undefined;
  logo_url: string | null | undefined;
  auth0_id: string | undefined;
  channel: string;
  attribute_changed: string;
}

export interface GetKitLogInput {
  auth0_id?: string | null;
  competition_id?: string | null;
}

export const createKitLog = async (log: KitLogInput) => {
  const resp = await axios.post("/.netlify/functions/createKitLog", {
    data: JSON.stringify(log),
    headers,
  });

  return resp.data;
};

export const getKitLog = async (log: GetKitLogInput) => {
  const resp = await axios.post("/.netlify/functions/getKitLog", {
    data: JSON.stringify(log),
    headers,
  });

  return resp.data;
};

export const getKitLogSummary = async (log: GetKitLogInput) => {
  const resp = await axios.post("/.netlify/functions/getKitLogSummary", {
    data: JSON.stringify(log),
    headers,
  });

  return resp.data;
};
