import React from "react";
import { Fixture } from "../types";
import { Typography } from "@mui/material";

type Props = {
  fixture: Fixture;
  source_team_id: string;
};

const GoalEvents = ({ fixture, source_team_id }: Props) => {
  return (
    <>
      {fixture.event
        .filter(
          (e) =>
            e.source_team_id === source_team_id &&
            !e.comments &&
            e.detail !== "Missed Penalty"
        )
        .map((e) => {
          const time = `${e.elapsed}${e.extra ? `+${e.extra}` : ""}`;
          const goalType =
            e.detail === "Own Goal"
              ? " (OG)"
              : e.detail === "Penalty"
              ? " (P)"
              : "";
          return (
            <Typography className="goal-text">
              {`${e.player_name} ${time}'${goalType}`}
            </Typography>
          );
        })}
    </>
  );
};

export default GoalEvents;
