import React, { useEffect, useState } from "react";
import PageWrapper from "../pages/PageWrapper";
import { getProducts } from "../../services/contentful";
import ProductRow from "../ProductRow";
import { Box, Typography } from "@mui/material";
import { useQuery } from "react-query";
import SkeletonFixture from "../SkeletonFixture";

const Shop = () => {
  const [products, setProducts] = useState<any[]>([]);
  const { isLoading, refetch: getProductsQuery } = useQuery(
    "get-products",
    async () => {
      return await getProducts();
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setProducts(data);
      },
    }
  );

  useEffect(() => {
    getProductsQuery();
  }, [getProductsQuery]);

  return (
    <PageWrapper>
      <Box display="flex" justifyContent="center">
        <Box>
          <Box mb={2}>
            <Typography
              variant="h5"
              className="section-header"
            >{`shop`}</Typography>
            <Box mt={1} className="sizing">
              <Typography>{`Curated merchandise that supports organizations working to make women's soccer more accessible.`}</Typography>
            </Box>
          </Box>
          {isLoading ? (
            <Box>
              <SkeletonFixture />
              <SkeletonFixture />
              <SkeletonFixture />
              <SkeletonFixture />
              <SkeletonFixture />
            </Box>
          ) : (
            <Box>
              {products.map((p) => {
                return <ProductRow product={p} key={p.fields.url} />;
              })}
            </Box>
          )}
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default Shop;
