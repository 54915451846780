import axios from "axios";
import { headers } from "./";

type CompetitionInput = {
  auth0_id?: string | null;
  competition_id?: string | null;
};

export const getFixtures = async (competition: CompetitionInput) => {
  const resp = await axios.post("/.netlify/functions/getFixtures", {
    headers,
    data: JSON.stringify(competition),
  });

  return resp.data;
};

export const getTable = async (competition: CompetitionInput) => {
  const resp = await axios.post("/.netlify/functions/getTable", {
    headers,
    data: JSON.stringify(competition),
  });

  return resp.data;
};

export const getTeams = async () => {
  const resp = await axios.post("/.netlify/functions/getTeams", {
    headers,
  });

  return resp.data;
};
