import React, { useEffect, useState } from "react";
import { Box, Chip, Grid, Avatar, Badge } from "@mui/material";
import { Types } from "ably";
import * as Ably from "ably/promises";
import { Shirt } from "../assets/Shirt";
import { TeamGroup, KitLog } from "../types";
import { createKitLog } from "../services/kit";
import { useMutation, useQueryClient } from "react-query";

const COLORS = [
  "#0047AB",
  "#FF0000",
  "#FFFFFF",
  "#333333",
  "#228B22",
  "#87CEEB",
  "#800000",
  "#FFD700",
];

export const DEFAULT_COLOR = "#a0a4b8ff";

type Props = {
  auth0Id: string | undefined;
  competitionId: number;
  teams: TeamGroup[];
  latestLog: KitLog | null;
  logSummary: Record<string, number>;
};

type KitOptions = {
  color: string | undefined | null;
  logo: string | undefined | null;
  logoUrl: string | undefined | null;
};

const KitPicker = ({
  auth0Id,
  competitionId,
  teams,
  latestLog,
  logSummary,
}: Props) => {
  const queryClient = useQueryClient();
  const CHANNEL_NAME = `kit-${competitionId}`;
  const [color, setColor] = useState(
    latestLog ? latestLog.color : DEFAULT_COLOR
  );
  const [team, setTeam] = useState(latestLog ? latestLog.logo : "");
  const [teamLink, setTeamLink] = useState(latestLog ? latestLog.logo_url : "");
  const [socketChannel, setSocketChannel] =
    useState<null | Types.RealtimeChannelPromise>(null);

  const { mutate: createKitLogMutation } = useMutation(createKitLog);

  useEffect(() => {
    const ablyInit = async () => {
      const connection = new Ably.Realtime.Promise({
        authUrl: `/.netlify/functions/ably-token-request`,
      });
      const channel = connection.channels.get(CHANNEL_NAME);

      await channel.subscribe(async (msg: Types.Message) => {
        console.log("Ably message received", msg);
        const data: any = msg.data;
        setTeam(data.logo);
        setTeamLink(data.logoUrl);
        setColor(data.color);

        queryClient.fetchQuery("get-kit-log-summary");

        // need to reset summary here somehow
      });

      if (!socketChannel) {
        setSocketChannel(channel);
      }
    };
    if (!socketChannel) {
      ablyInit();
    }
  }, [
    CHANNEL_NAME,
    color,
    competitionId,
    queryClient,
    socketChannel,
    team,
    teamLink,
  ]);

  const addMessage = async (newData: KitOptions, attribute: string) => {
    if (socketChannel) {
      await socketChannel.publish(`kit-message`, {
        competitionId,
        ...newData,
        auth0Id,
        channel: CHANNEL_NAME,
      });
      // save to db
      createKitLogMutation(
        {
          competition_id: competitionId,
          auth0_id: auth0Id,
          channel: CHANNEL_NAME,
          color: newData.color,
          logo: newData.logo,
          logo_url: newData.logoUrl,
          name: `kit-message`,
          attribute_changed: attribute,
        },
        {
          onSuccess: () => {
            queryClient.fetchQuery("get-kit-log");
            queryClient.fetchQuery("get-kit-log-summary");
          },
        }
      );
    }
  };

  useEffect(() => {
    setColor(latestLog ? latestLog.color : DEFAULT_COLOR);
    setTeam(latestLog ? latestLog.logo : "");
    setTeamLink(latestLog ? latestLog.logo_url : "");
  }, [latestLog]);

  const handleTeamLink = (newTeamLink: string) => {
    const newData = { logo: team, logoUrl: newTeamLink, color };
    if (newTeamLink !== teamLink) {
      addMessage(newData, "logo_url");
      setTeamLink(newTeamLink);
    }
  };

  const handleTeam = (newTeam: string) => {
    const newData = { logo: newTeam, logoUrl: teamLink, color };
    if (newTeam !== team) {
      addMessage(newData, "logo");
      setTeam(newTeam);
    }
  };

  const handleColor = (newColor: string) => {
    const newData = { logo: team, logoUrl: teamLink, color: newColor };
    if (newColor !== color) {
      addMessage(newData, "color");
      setColor(newColor);
    }
  };

  return (
    <Box
      bgcolor="white"
      borderRadius={1}
      p={1}
      width="350px"
      display="flex"
      justifyContent="center"
      flexDirection={"column"}
    >
      <Shirt fill={color} badge={team} link={teamLink} />

      <Grid container mt={2} spacing={1}>
        {COLORS.map((hexColor) => {
          const borderColor =
            hexColor === color ? "2px solid black" : "2px solid gray";
          return (
            <Grid item>
              <Badge badgeContent={logSummary[hexColor] || 0} color="primary">
                <Chip
                  sx={{
                    bgcolor: hexColor,
                    cursor: "pointer",
                    color: hexColor,
                    border: borderColor,
                  }}
                  label="i"
                  onClick={() => handleColor(hexColor)}
                  clickable={false}
                />
              </Badge>
            </Grid>
          );
        })}
      </Grid>
      <Grid container mt={2} spacing={1}>
        {teams.map((teamItem) => {
          const hasLink = !!teamItem.logo_url;
          const borderColor =
            (teamItem.logo && teamItem.logo === team) ||
            (teamItem.logo_url && teamItem.logo_url === teamLink)
              ? "2px solid black"
              : "2px solid gray";
          return (
            <Grid item>
              <Badge
                badgeContent={
                  logSummary[hasLink ? teamItem.logo_url : teamItem.logo] || 0
                }
                color="primary"
              >
                <Chip
                  sx={{
                    cursor: "pointer",
                    border: borderColor,
                  }}
                  label={hasLink ? "" : teamItem.logo}
                  onClick={
                    hasLink
                      ? () => handleTeamLink(teamItem.logo_url)
                      : () => handleTeam(teamItem.logo)
                  }
                  clickable={false}
                  avatar={
                    hasLink ? <Avatar src={teamItem.logo_url} /> : undefined
                  }
                />
              </Badge>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default KitPicker;
