import React, { useContext } from "react";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CompetitionContext } from "../context/CompetitionContext";

const Title = () => {
  const navigate = useNavigate();
  const competitionContext = useContext(CompetitionContext);
  return (
    <Box display="flex" justifyContent="center" onClick={() => navigate("/")}>
      <Box mr={1}>
        <Typography className="wwc-title yellow">{`{`}</Typography>
      </Box>
      <Typography className="wwc-title">{`${
        competitionContext?.competition?.name || ""
      }`}</Typography>
      <Box ml={1}>
        <Typography className="wwc-title yellow">{`}`}</Typography>
      </Box>
    </Box>
  );
};

export default Title;
