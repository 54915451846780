import React, { useState } from "react";
import { Prediction } from "../types";
import { formatLocalDate, getRoundText } from "../services/util";
import { Typography, Box, Chip, Button, Grid } from "@mui/material";
import { statusMapping } from "../constants";
import { CountdownElement } from "./Countdown";
import AlertDialog from "./AlertDialog";
import { cancelPrediction } from "../services/prediction";
import { useMutation, useQueryClient } from "react-query";
import PredictDialog from "./PredictDialog";
import { hoursUntilDate } from "../services/util";

type Props = {
  prediction: Prediction;
};

const PredictionRow = ({ prediction }: Props) => {
  const { fixture } = prediction;
  const { dt_start, home_team, away_team, round } = fixture;
  const { hasStarted } = hoursUntilDate(dt_start);
  const [openCancelAlert, setOpenCancelAlert] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const isGroupStage = round.includes("Group") || round.includes("Regular");
  const roundText = getRoundText(round, home_team.group);
  const queryClient = useQueryClient();

  const { isLoading: isCancelLoading, mutate: cancelPredictionMutation } =
    useMutation(
      async () => {
        if (!prediction) return;
        return await cancelPrediction({ id: prediction.id });
      },
      {
        onSuccess: () => {
          queryClient.fetchQuery("get-predictions");
          queryClient.fetchQuery("get-user");
          setOpenCancelAlert(false);
        },
      }
    );

  const onCancel = async () => {
    cancelPredictionMutation();
  };

  const pointDifference =
    prediction.is_winner === true
      ? `+${prediction.points_bet}`
      : `-${prediction.points_bet}`;

  return (
    <Box className="fixture-box" display="flex" minHeight={140}>
      <Grid container alignItems="center">
        <Grid item xs={7}>
          <Box
            display="flex"
            flexDirection="column"
            p={1}
            alignItems="flex-start"
          >
            <Box display="flex">
              <Chip
                size="small"
                label={roundText}
                className="group-chip"
                style={{ backgroundColor: "#fff689ff" }}
              />

              <Chip
                size="small"
                label={
                  `${statusMapping[prediction.status]?.label} ${
                    prediction.status.includes("settled") ? pointDifference : ""
                  }` || ""
                }
                className="group-chip"
                style={{
                  backgroundColor: `${
                    statusMapping[prediction.status]?.color || undefined
                  }`,
                }}
              />
            </Box>

            <Typography
              className="fixture-text"
              style={{ fontSize: "14px" }}
            >{`${formatLocalDate(dt_start).split(",")[1]}`}</Typography>
            <Box display="flex" flexDirection="column">
              <Typography>
                <span
                  className={
                    prediction.winner_source_team_id !==
                    home_team.source_team_id
                      ? "hide"
                      : undefined
                  }
                >
                  ✅{" "}
                </span>
                {` ${home_team.name}`}
              </Typography>
              <Typography>
                {" "}
                <span
                  className={
                    prediction.winner_source_team_id !==
                    away_team.source_team_id
                      ? "hide"
                      : undefined
                  }
                >
                  ✅{" "}
                </span>
                {`${away_team.name}`}
              </Typography>
              {isGroupStage && (
                <Typography>
                  {" "}
                  <span
                    className={
                      prediction.winner_source_team_id !== "1"
                        ? "hide"
                        : undefined
                    }
                  >
                    ✅{" "}
                  </span>
                  {`Draw`}
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            m={0}
            p={0}
            ml={-2}
          >
            <CountdownElement
              number={prediction.points_bet}
              title={"points bet"}
              m={0}
              p={0}
              hideLeadingZero
            />
            {prediction.status === "active" && (
              <Box
                display="flex"
                m={0}
                p={0}
                justifySelf="center"
                alignSelf="center"
              >
                <Box m={1}>
                  <Button
                    className="prediction-row-button"
                    variant="contained"
                    size="small"
                    onClick={() => setOpenEdit(true)}
                    style={{
                      visibility: hasStarted ? "hidden" : "visible",
                    }}
                  >
                    Update
                  </Button>
                </Box>
                <Box m={1}>
                  <Button
                    className="prediction-row-button"
                    variant="contained"
                    onClick={() => setOpenCancelAlert(true)}
                    size="small"
                    style={{
                      visibility: hasStarted ? "hidden" : "visible",
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
        <AlertDialog
          open={openCancelAlert}
          setOpen={setOpenCancelAlert}
          buttonText={"confirm"}
          content={"Are you sure you want to cancel this prediction?"}
          buttonClickHandler={onCancel}
          title="Confirm Cancel"
          isLoading={isCancelLoading}
        />
        <PredictDialog
          open={openEdit}
          setOpen={setOpenEdit}
          fixture={fixture}
          roundText={roundText}
          isGroupStage={isGroupStage}
          type={"update"}
          prediction={prediction}
        />
      </Grid>
    </Box>
  );
};

export default PredictionRow;
