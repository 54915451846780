import React from "react";
import { Fixture, Prediction } from "../types";
import { Typography, Box } from "@mui/material";
import PastFixtureRow from "./PastFixtureRow";

type Props = {
  fixtures: Fixture[];
  predictions: Prediction[];
};

const PastSchedule = ({ fixtures, predictions }: Props) => {
  return (
    <Box display="flex" justifyContent="center">
      <Box>
        <Box mb={2}>
          <Typography
            variant="h5"
            className="section-header"
          >{`past matches`}</Typography>
        </Box>

        <Box>
          {[...fixtures].reverse().map((fix) => {
            const prediction = predictions.find(
              (p) => p.source_fixture_id === fix.source_fixture_id
            );
            return (
              <PastFixtureRow
                fixture={fix}
                key={fix.source_fixture_id}
                prediction={prediction}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default PastSchedule;
