import React, { useState, useEffect, useCallback } from "react";
import PageWrapper from "../pages/PageWrapper";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import { getPlayers } from "../../services/contentful";
import { getPlayerList } from "../../services/player";
import { useQuery } from "react-query";
import moment from "moment";
import { Player, PlayerInfo, RawPlayer } from "../../types";
import StarIcon from "@mui/icons-material/Star";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import AlertDialog from "../AlertDialog";
import { useSearchParams } from "react-router-dom";
import SkeletonFixture from "../SkeletonFixture";

const PlayerSpotlight = () => {
  const [currentPlayer, setCurrentPlayer] = useState<Player | null>(null);
  const [allPlayers, setAllPlayers] = useState<RawPlayer[]>([]);
  const [showHint, setShowHint] = useState(0);
  const [numGuesses, setNumGuesses] = useState(0);
  const [isRevealed, setIsRevealed] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const placeholderText = "Lorem ipsum dolor sit amet, consectetur";
  const [playerList, setPlayerList] = useState<PlayerInfo[]>([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("default");
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const [searchParams] = useSearchParams();
  const pastDate = searchParams.get("date");
  const [targetDate, setTargetDate] = useState(pastDate || currentDate);
  const showRandom = true;

  const findAndSetCurrentPlayer = useCallback(
    (data: RawPlayer[]) => {
      data.forEach((element: RawPlayer) => {
        if (
          element.fields.postDate === targetDate &&
          targetDate <= currentDate
        ) {
          setCurrentPlayer(element.fields);
        }
      });
      const livePlayers = data.filter((player) => player.fields.isLive);
      const random =
        livePlayers[Math.floor(Math.random() * livePlayers.length)];
      if (showRandom && random) {
        setCurrentPlayer(random.fields);
      }
    },
    [currentDate, targetDate, showRandom]
  );

  const { refetch: getPlayersQuery } = useQuery(
    "get-players",
    async () => {
      return await getPlayers();
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setAllPlayers(data);
        findAndSetCurrentPlayer(data);
      },
    }
  );

  const { isLoading, refetch: getPlayerListQuery } = useQuery(
    "get-player-list",
    async () => {
      return await getPlayerList();
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setPlayerList(
          data.map((player: PlayerInfo) => {
            return {
              id: player.source_player_id,
              label: `${player.display_name} (${player.team.name})`,
            };
          })
        );
      },
    }
  );

  useEffect(() => {
    getPlayersQuery();
  }, [getPlayersQuery]);

  useEffect(() => {
    getPlayerListQuery();
  }, [getPlayerListQuery]);

  useEffect(() => {
    setTargetDate(pastDate || currentDate);
    findAndSetCurrentPlayer(allPlayers);
  }, [pastDate, currentDate, findAndSetCurrentPlayer, allPlayers]);

  const handleClose = () => setShowSnackbar(false);

  const selectHandler = (e: any, newValue: any) => {
    if (newValue?.id) {
      if (newValue?.id === currentPlayer?.playerSourceId) {
        setIsRevealed(true);
        setAlertContent(
          `You got it! The featured player is ${newValue.label}.`
        );
        setOpenAlert(true);
      } else {
        setAlertContent(`${newValue.label} is not right. Try again.`);
        setShowSnackbar(true);
      }
      setNumGuesses(numGuesses + 1);
    }
  };

  const playAgain = () => {
    setShowHint(0);
    setNumGuesses(0);
    setIsRevealed(false);
    findAndSetCurrentPlayer(allPlayers);
  };

  if (isLoading || (showRandom && !currentPlayer)) {
    return (
      <PageWrapper>
        <Box display="flex" justifyContent="center">
          <Box>
            <Box mb={2}>
              <Typography variant="h5" className={"section-header"}>
                {"player spotlight"}
              </Typography>
              <Box mt={2}>
                <SkeletonFixture />
              </Box>
            </Box>
          </Box>
        </Box>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      {currentPlayer ? (
        <Box display="flex" justifyContent="center">
          <Box>
            <Box mb={2}>
              <Typography variant="h5" className={"section-header"}>
                {isRevealed ? `${currentPlayer.name}` : "player spotlight"}
              </Typography>
              {!isRevealed && (
                <Box mt={1}>
                  <Typography>
                    Guess who the featured player is. Use a hint if you need
                    help.
                  </Typography>
                </Box>
              )}
              {!isRevealed && (
                <Box>
                  <Chip
                    clickable
                    icon={<LightbulbIcon />}
                    onClick={() => setShowHint(showHint + 1)}
                    size="small"
                    label={`Reveal Hint (${
                      currentPlayer.bullets.length - showHint - 1
                    })`}
                    className="group-chip"
                    style={{ backgroundColor: "#7ae7c7ff", marginRight: "5px" }}
                    disabled={
                      currentPlayer.bullets.length - 1 <= showHint || isRevealed
                    }
                  />
                  <Chip
                    clickable
                    icon={<VisibilityIcon />}
                    onClick={() => setIsRevealed(true)}
                    size="small"
                    label={`Reveal Player`}
                    className="group-chip"
                    style={{ backgroundColor: "#a0a4b8ff", marginRight: "5px" }}
                    disabled={isRevealed}
                  />
                </Box>
              )}
              <Box mt={2}>
                {!isRevealed && (
                  <Autocomplete
                    onChange={(e: any, newValue: any) =>
                      selectHandler(e, newValue)
                    }
                    disablePortal
                    options={playerList}
                    // sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Guess player"
                        className="player-select"
                        id="select-player"
                        name="guess-player"
                      />
                    )}
                  />
                )}
                <Box display="flex" flexDirection="column" mt={2} mb={1}>
                  <Box alignSelf="center" display="flex" flexDirection="column">
                    <img
                      src={currentPlayer.image.fields.file.url}
                      alt={isRevealed ? currentPlayer.name : "player spotlight"}
                      width={230}
                      className={isRevealed ? undefined : "blur-image"}
                    />

                    <Typography
                      className={isRevealed ? "link" : "link hidden-text"}
                      component="a"
                      target="_blank"
                      href={isRevealed ? currentPlayer.imageSource : undefined}
                    >
                      {isRevealed
                        ? `via @${currentPlayer.imageHandle}`
                        : "via @playername"}
                    </Typography>
                    {isRevealed && (
                      <>
                        <Typography
                          className={isRevealed ? undefined : "hidden-text"}
                        >
                          {isRevealed ? currentPlayer.country : "🏳️ country"}
                        </Typography>
                        <Typography
                          className={isRevealed ? undefined : "hidden-text"}
                        >
                          {isRevealed ? currentPlayer.club : "🏳️ club"}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
                {currentPlayer.bullets.map((bullet, i) => (
                  <Typography>
                    <Box display="flex" className="sizing">
                      <StarIcon className="star" />
                      <span
                        className={
                          showHint >= i || isRevealed
                            ? undefined
                            : "hidden-text"
                        }
                      >
                        {showHint >= i || isRevealed ? bullet : placeholderText}
                      </span>
                    </Box>
                  </Typography>
                ))}
              </Box>
            </Box>
            {isRevealed && !showRandom && (
              <Box mb={2}>
                <Typography variant="h5" className={"section-header"}>
                  {"past profiles"}
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  mt={2}
                >
                  {allPlayers
                    .filter(
                      (e) =>
                        e.fields.postDate <= currentDate &&
                        e.fields.postDate !== targetDate
                    )
                    .map((p) => (
                      <Button
                        onClick={() =>
                          (window.location.href = `${window.location.origin}/player?date=${p.fields.postDate}`)
                        }
                      >
                        {p.fields.postDate}
                      </Button>
                    ))}
                </Box>
              </Box>
            )}
            {isRevealed && showRandom && (
              <Button variant="contained" onClick={playAgain}>
                Play Again
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <Box mb={2}>
          <Typography variant="h5" className={"section-header"}>
            {"past profiles"}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            mt={2}
          >
            {allPlayers
              .filter(
                (e) =>
                  e.fields.postDate <= currentDate &&
                  e.fields.postDate !== targetDate
              )
              .map((p) => (
                <Button
                  onClick={() =>
                    (window.location.href = `${window.location.origin}/player?date=${p.fields.postDate}`)
                  }
                >
                  {p.fields.postDate}
                </Button>
              ))}
          </Box>
        </Box>
      )}
      {openAlert && (
        <AlertDialog
          open={openAlert}
          setOpen={setOpenAlert}
          content={alertContent}
          title="Banger"
          isLoading={false}
        />
      )}

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ height: "100%" }}
      >
        <Alert onClose={handleClose} severity={"error"} sx={{ width: "100%" }}>
          {alertContent}
        </Alert>
      </Snackbar>
    </PageWrapper>
  );
};

export default PlayerSpotlight;
