import React, { useState } from "react";
import { Box, TableRow, TableCell } from "@mui/material";
import { UserDetails, Leaderboard } from "../types";
import { useAuth0 } from "@auth0/auth0-react";
import UserDetailsDialog from "./UserDetailsDialog";
import LoginDialog from "./LoginDialog";

type Props = {
  userDetails: UserDetails | undefined;
  i: number;
  row: Leaderboard;
  isSameUser: boolean;
  isOwner: boolean;
  groupId?: string;
  groupName?: string;
  isGlobal?: boolean;
};

const GroupUserRow = ({
  userDetails,
  i,
  row,
  isSameUser,
  groupId,
  groupName,
  isOwner,
  isGlobal,
}: Props) => {
  const { user } = useAuth0();
  const [openUser, setOpenUser] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const handleClickOpen = () => {
    if (user?.sub) {
      setOpenUser(true);
    } else {
      setOpenLogin(true);
    }
  };

  return (
    <TableRow key={row.auth0_id}>
      <TableCell component="th" scope="row" className="small rank">
        {i + 1}
      </TableCell>
      <TableCell component="th" scope="row" className="small leaderboard-name">
        <Box
          display="flex"
          alignItems="center"
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={handleClickOpen}
        >
          {`${
            isGlobal
              ? row.display_name || "Anonymous"
              : row.display_name || row.email
          } ${row.auth0_id === user?.sub ? "(you)" : ""}`}
        </Box>
      </TableCell>
      <TableCell align="right" className="small">
        {row.points_bet}
      </TableCell>
      <TableCell align="right" className="small">
        {row.total_points}
      </TableCell>
      {openUser && userDetails && (
        <UserDetailsDialog
          open={openUser}
          setOpen={setOpenUser}
          userDetails={userDetails}
          isOwner={isOwner}
          isSameUser={isSameUser}
          groupId={groupId}
          groupName={groupName}
          isGlobal={isGlobal}
        />
      )}
      <LoginDialog
        open={openLogin}
        setOpen={setOpenLogin}
        title="View Details"
        description="You must have an account in order to view details."
      />
    </TableRow>
  );
};

export default GroupUserRow;
