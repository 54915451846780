import React from "react";
import Schedule from "../Schedule";
import PageWrapper from "../pages/PageWrapper";

const Matches = () => {
  return (
    <PageWrapper>
      <Schedule />
    </PageWrapper>
  );
};

export default Matches;
