import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  Paper,
  Divider,
} from "@mui/material";
import { GroupMember, UserDetails } from "../types";
import InviteMemberDialog from "./InviteMemberDialog";
import { useAuth0 } from "@auth0/auth0-react";
import GroupDialog from "./GroupDialog";
import AlertDialog from "./AlertDialog";
import { useMutation, useQueryClient } from "react-query";
import { createOrUpdateGroup, deleteGroupMember } from "../services/group";
import GroupUserRow from "./GroupUserRow";
import { CompetitionContext } from "../context/CompetitionContext";

type Props = {
  g: GroupMember;
  usersDetails: UserDetails[];
};

const MyGroups = ({ g, usersDetails }: Props) => {
  const { user } = useAuth0();
  const competitionContext = useContext(CompetitionContext);
  const [userToDelete, setUserToDelete] = useState<string | null>(null);
  const [openInvite, setOpenInvite] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openLeaveAlert, setOpenLeaveAlert] = useState(false);
  const queryClient = useQueryClient();

  const { isLoading, mutate: createOrUpdateGroupMutation } = useMutation(
    async () => {
      if (user?.sub && competitionContext?.competition?.id) {
        return await createOrUpdateGroup({
          owner_auth0_id: user.sub,
          name: g.groups.name,
          description: g.groups.description || undefined,
          id: g.groups.id,
          shouldDelete: true,
        });
      }
    },
    {
      onSuccess: () => {
        setOpenAlert(false);
        queryClient.fetchQuery("get-leaderboard");
      },
    }
  );

  const { isLoading: isLoadingLeave, mutate: deleteGroupMemberMutation } =
    useMutation(
      async () => {
        if (user?.sub && userToDelete) {
          return await deleteGroupMember({
            auth0_id: userToDelete,
            group_id: g.groups.id,
          });
        }
      },
      {
        onSuccess: () => {
          setOpenLeaveAlert(false);
          queryClient.fetchQuery("get-leaderboard");
        },
      }
    );

  const handleDeleteSelf = () => {
    if (user?.sub) {
      setOpenLeaveAlert(true);
      setUserToDelete(user?.sub);
    }
  };

  const isOwner = g.is_owner === true;
  return (
    <Box mt={2}>
      <Box mt={2} mb={1}>
        <Divider />
      </Box>
      <Box mb={2}>
        <Typography variant="h6">{g.groups.name}</Typography>
        <Typography style={{ color: "#a0a4b8ff", fontSize: "13px" }}>
          {g.groups.description}
        </Typography>
        {isOwner ? (
          <Box display="flex" mt={1}>
            <Box mr={2}>
              <Button
                variant="contained"
                onClick={() => setOpenInvite(true)}
                size="small"
              >
                Add Members
              </Button>
            </Box>
            <Box mr={2}>
              <Button
                variant="contained"
                onClick={() => setOpenEdit(true)}
                size="small"
              >
                Edit
              </Button>
            </Box>
            <Box mr={2}>
              <Button
                variant="contained"
                onClick={() => setOpenAlert(true)}
                size="small"
                color="error"
              >
                Delete
              </Button>
            </Box>
            <InviteMemberDialog
              open={openInvite}
              setOpen={setOpenInvite}
              code={g.groups.code}
              group={g.groups}
            />
          </Box>
        ) : (
          <Box display="flex" mt={1}>
            <Box mr={2}>
              <Button
                variant="contained"
                onClick={() => setOpenInvite(true)}
                size="small"
              >
                Add Members
              </Button>
            </Box>
            <Box mr={2}>
              <Button
                variant="contained"
                onClick={handleDeleteSelf}
                size="small"
                color="error"
              >
                Leave Group
              </Button>
            </Box>
            <InviteMemberDialog
              open={openInvite}
              setOpen={setOpenInvite}
              code={g.groups.code}
              group={g.groups}
            />
          </Box>
        )}
      </Box>
      <TableContainer component={Paper} className="leaderboard-container">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">Points in Bets</TableCell>
              <TableCell align="right">Total Points</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {g.groups.leaderboard.map((row, i) => {
              const userDetails = usersDetails.find(
                (u) => u.auth0_id === row.auth0_id
              );
              return (
                <GroupUserRow
                  i={i}
                  row={row}
                  userDetails={userDetails}
                  isSameUser={row.auth0_id === user?.sub}
                  groupId={g.groups.id}
                  groupName={g.groups.name}
                  isOwner={g.is_owner}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {openEdit && (
        <GroupDialog
          open={openEdit}
          setOpen={setOpenEdit}
          type="update"
          group={g.groups}
        />
      )}
      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        buttonText={"confirm"}
        content={"Are you sure you want to delete this group?"}
        buttonClickHandler={createOrUpdateGroupMutation}
        title="Confirm Delete Group"
        isLoading={isLoading}
      />
      <AlertDialog
        open={openLeaveAlert}
        setOpen={setOpenLeaveAlert}
        buttonText={"confirm"}
        content={"Are you sure you want to leave this group?"}
        buttonClickHandler={deleteGroupMemberMutation}
        title="Confirm Leave Group"
        isLoading={isLoadingLeave}
      />
    </Box>
  );
};

export default MyGroups;
