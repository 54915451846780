import React, { useState } from "react";
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { updateDisplayName } from "../services/user";
import { useMutation, useQueryClient } from "react-query";
import AlertDialog from "./AlertDialog";
import { useNavigate } from "react-router-dom";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  auth0Id: string;
  initialDisplayName: string | null | undefined;
};

const PredictDialog = ({
  open,
  setOpen,
  auth0Id,
  initialDisplayName,
}: Props) => {
  const [displayName, setDisplayName] = useState("");

  const [nameError, setNameError] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const queryClient = useQueryClient();
  const buttonText = "Update Display Name";
  const navigate = useNavigate();

  const { isLoading, mutate: updateDisplayNameMutation } = useMutation(
    async () => {
      return await updateDisplayName({
        auth0_id: auth0Id,
        display_name: displayName,
      });
    },
    {
      onSuccess: () => {
        queryClient.fetchQuery("get-user");
        queryClient.fetchQuery("get-leaderboard");
        setOpen(false);
        setOpenAlert(true);
      },
    }
  );

  const handleClose = () => {
    setNameError("");
    setOpen(false);
  };

  const onNameChangeHandler = (e: any) => {
    const name = e.target.value;
    if (name.length >= 3 && name.length <= 20) {
      setNameError("");
    }
    setDisplayName(e.target.value);
  };

  const handleSubmit = async () => {
    if (displayName.length < 3 || displayName.length > 20) {
      setNameError("Display name must be between 3 and 20 characters.");
      return;
    }
    updateDisplayNameMutation();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box> Update Display Name </Box>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box mb={1}>
            <Typography>
              Your display name will appear in the leaderboard.
            </Typography>
          </Box>
          <TextField
            variant="outlined"
            defaultValue={initialDisplayName}
            margin="dense"
            id="name"
            label="Display Name"
            InputProps={{
              inputProps: { min: 3, max: 20 },
            }}
            onChange={(e) => onNameChangeHandler(e)}
          />
          {nameError && <Typography color="error">{nameError}</Typography>}
        </DialogContent>
        <DialogActions>
          <Box display="flex" width="100%" justifyContent="center" mb={2}>
            <Box alignSelf="center">
              <Button
                onClick={handleSubmit}
                variant="contained"
                disabled={isLoading}
              >
                {isLoading && (
                  <CircularProgress
                    color="inherit"
                    className="spinner"
                    size="1rem"
                  />
                )}
                {buttonText}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        buttonText={"view leaderboard"}
        content={"Your display name has been saved successfully."}
        buttonClickHandler={() => navigate("/leaderboard")}
        title="Display Name Saved"
        isLoading={false}
      />
    </div>
  );
};
export default PredictDialog;
