import React, { useEffect, useState, useContext } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  MenuItem,
  Badge,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import Title from "./Title";
import { useNavigate } from "react-router-dom";
import { getUser } from "../services/user";
import { getCompetition } from "../services/competition";
import { UserContext } from "../context/UserContext";
import { CompetitionContext } from "../context/CompetitionContext";
import { useQuery } from "react-query";
import DisplayNameDialog from "./DisplayNameDialog";
import { useAuth0Helpers } from "../hooks/useAuth0Helpers";
import AlertDialog from "./AlertDialog";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useAuth0 } from "@auth0/auth0-react";
import SwitchCompetitionDialog from "./SwitchCompetitionDialog";

const pages = [
  { title: "Matches", url: "/matches", isVisible: true },
  { title: "Leaderboard", url: "/leaderboard", isVisible: true },
  { title: "Players", url: "/player", isVisible: true },
  { title: "Teams", url: "/teams", isVisible: true },
  { title: "Trivia", url: "/trivia", isVisible: true },
  { title: "Shop", url: "/shop", isVisible: true },
  { title: "About", url: "/about", isVisible: true },
];

const WelcomeMessage = () => {
  const competitionContext = useContext(CompetitionContext);
  return (
    <Box>
      <Typography variant="h6" className="new-points">
        +500 points
      </Typography>
      <Typography>
        {`Welcome! You have 500 points to make predictions with throughout the ${
          competitionContext?.competition?.num_matches || ""
        }
        matches of the ${
          competitionContext?.competition?.name || ""
        }. Select Switch Competition from the menu to play a different competition.`}
      </Typography>
    </Box>
  );
};

const ResponsiveAppBar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openCompDialog, setOpenCompDialog] = useState(false);
  const [points, setPoints] = useState<null | number>(null);
  const [displayName, setDisplayName] = useState<null | string | undefined>(
    null
  );
  const [showWelcome, setShowWelcome] = useState(false);
  const { user, isLoading, isAuthenticated } = useAuth0();
  const { handleLogin, handleSignup, handleLogout } = useAuth0Helpers();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const userContext = useContext(UserContext);
  const competitionContext = useContext(CompetitionContext);

  const { refetch: getUserQuery } = useQuery(
    "get-user",
    async () => {
      if (user?.sub && user?.email) {
        return await getUser({ auth0_id: user.sub, email: user.email });
      }
    },
    {
      enabled: false,
      onSuccess: (data) => {
        if (data) {
          if (data.welcome === true) {
            setShowWelcome(true);
          }
          const { points, display_name } = data;
          userContext?.setUser(data);
          setPoints(points);
          setDisplayName(display_name || undefined);
        }
      },
    }
  );

  const { refetch: getCompetitionQuery } = useQuery(
    "get-competition",
    async () => {
      return await getCompetition({
        auth0_id: userContext?.user?.auth0_id,
        competition_id: localStorage.getItem("last_competition_id"),
      });
    },
    {
      enabled: false,
      onSuccess: (data) => {
        competitionContext?.setCompetition(data);
      },
    }
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onDisplayNameClick = () => {
    setOpen(true);
    handleCloseUserMenu();
  };

  useEffect(() => {
    if (user?.sub) {
      getUserQuery();
    }
  }, [user, getUserQuery]);

  useEffect(() => {
    getCompetitionQuery();
  }, [getCompetitionQuery]);

  useEffect(() => {
    if (userContext?.user?.auth0_id) {
      getCompetitionQuery();
    }
  }, [getCompetitionQuery, userContext?.user]);

  const handleCompClick = () => {
    setOpenCompDialog(true);
    setAnchorElNav(null);
  };

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Title />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem key={"switch"} onClick={handleCompClick}>
                <Typography textAlign="center">Switch Competition</Typography>
              </MenuItem>
              {pages.map((page) => (
                <MenuItem
                  key={page.title}
                  onClick={() => navigate(page.url)}
                  disabled={window.location.href.includes(page.url)}
                >
                  <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Title />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              key={"switch"}
              onClick={handleCompClick}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Switch Competition
            </Button>
            {pages.map((page) => (
              <Button
                key={page.title}
                onClick={() => navigate(page.url)}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page.title}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {!isLoading && (
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Badge
                  color="error"
                  badgeContent={1}
                  overlap="circular"
                  invisible={displayName !== undefined}
                >
                  <Avatar
                    alt={user ? user.nickname : ""}
                    // src="/static/images/avatar/2.jpg"
                  >
                    {user && user.nickname
                      ? user.nickname.slice(0, 1)
                      : undefined}
                  </Avatar>
                </Badge>
              </IconButton>
            )}
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {user && (
                <MenuItem disabled>
                  <Box>
                    <Typography className="email">{user.email}</Typography>
                    {points !== null && (
                      <Typography className="email">{`Available Points: ${points}`}</Typography>
                    )}
                  </Box>
                </MenuItem>
              )}
              {displayName && (
                <MenuItem onClick={onDisplayNameClick}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography>{displayName}</Typography>
                    <Box ml={2}>
                      <EditIcon fontSize="small" />
                    </Box>
                  </Box>
                </MenuItem>
              )}

              {user && !displayName && (
                <MenuItem onClick={onDisplayNameClick}>
                  {/* <Badge
                    color="error"
                    variant="dot"
                    style={{ paddingRight: "10px" }}
                  /> */}

                  <Typography textAlign="center">
                    {"Set Display Name"}
                  </Typography>
                  <FiberManualRecordIcon
                    color="error"
                    style={{ fontSize: "10px", marginLeft: "10px" }}
                  />
                </MenuItem>
              )}

              {isAuthenticated && (
                <MenuItem
                  onClick={() => navigate("/predictions")}
                  disabled={window.location.href.includes("/predictions")}
                >
                  <Typography textAlign="center">{"My Predictions"}</Typography>
                </MenuItem>
              )}
              {isAuthenticated && (
                <MenuItem
                  onClick={() => navigate("/leaderboard")}
                  disabled={window.location.href.includes("/leaderboard")}
                >
                  <Typography textAlign="center">{"My Leaderboard"}</Typography>
                </MenuItem>
              )}

              <MenuItem
                onClick={() => navigate("/kit")}
                disabled={window.location.href.includes("/kit")}
              >
                <Typography textAlign="center">{"Kit Design"}</Typography>
              </MenuItem>

              {isAuthenticated ? (
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">{"Logout"}</Typography>
                </MenuItem>
              ) : (
                <Box>
                  <MenuItem onClick={handleSignup}>
                    <Typography textAlign="center">{"Sign up"}</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleLogin}>
                    <Typography textAlign="center">{"Log in"}</Typography>
                  </MenuItem>
                </Box>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      {user?.sub && (
        <DisplayNameDialog
          open={open}
          setOpen={setOpen}
          auth0Id={user?.sub}
          initialDisplayName={displayName}
        />
      )}
      <AlertDialog
        open={showWelcome}
        setOpen={setShowWelcome}
        buttonText={"learn more"}
        content={<WelcomeMessage />}
        buttonClickHandler={() => navigate("/about")}
        title={competitionContext?.competition?.name || ""}
        isLoading={false}
      />
      {competitionContext?.competition?.id && !isLoading && (
        <SwitchCompetitionDialog
          currentCompId={competitionContext?.competition?.id}
          auth0Id={user?.sub}
          isOpen={openCompDialog}
          handleClose={() => setOpenCompDialog(false)}
        />
      )}
    </AppBar>
  );
};
export default ResponsiveAppBar;
