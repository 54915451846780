import React, { useState, useEffect } from "react";
import PageWrapper from "../pages/PageWrapper";
import { Box, Typography, Card, Snackbar, Alert } from "@mui/material";
import { getTrivia } from "../../services/contentful";
import { useQuery } from "react-query";
import { Question } from "../../types";
import { shuffleArray } from "../../services/util";
import AlertDialog from "../AlertDialog";

const Trivia = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [queryData, setQueryData] = useState<Question[]>([]);
  const [numCorrect, setNumCorrect] = useState(0);
  const [options, setOptions] = useState<string[]>([]);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("default");
  const { isLoading, refetch: getTriviaQuery } = useQuery(
    "get-trivia",
    async () => {
      return await getTrivia();
    },
    {
      enabled: false,
      onSuccess: (data: Question[]) => {
        const liveQuestions = data.filter((q) => q.fields.isLive);
        setQueryData(liveQuestions);
        setQuestions(shuffleArray(liveQuestions, 5));
      },
    }
  );

  useEffect(() => {
    getTriviaQuery();
  }, [getTriviaQuery]);

  useEffect(() => {
    if (questions.length > currentQuestionIndex)
      setOptions(shuffleArray(questions[currentQuestionIndex].fields.options));
  }, [currentQuestionIndex, setOptions, questions]);

  const clickHandler = (option: string, index: number) => {
    const isCorrect =
      option === questions[currentQuestionIndex].fields.correctOption;
    const newNumCorrect = isCorrect ? numCorrect + 1 : numCorrect;
    setShowAnswer(true);
    setShowSuccess(isCorrect);
    setOpen(true);
    setSelectedOptionIndex(index);
    setTimeout(() => {
      setShowAnswer(false);
      setNumCorrect(newNumCorrect);
      const nextIndex =
        currentQuestionIndex + 1 < questions.length
          ? currentQuestionIndex + 1
          : 0;
      if (nextIndex === 0) {
        setAlertContent(
          `Your final score is ${newNumCorrect}/${
            currentQuestionIndex + 1
          } (${Math.floor(
            (newNumCorrect / (currentQuestionIndex + 1)) * 100
          )}%) `
        );
        setOpenAlert(true);
        setNumCorrect(0);
        setQuestions(shuffleArray(queryData, 5));
      }
      setCurrentQuestionIndex(nextIndex);
    }, 3000);
  };

  const handleClose = () => setOpen(false);

  return (
    <PageWrapper>
      <Box display="flex" justifyContent="center">
        <Box>
          <Box mb={2} className="sizing">
            <Box>
              <Typography
                variant="h5"
                className="section-header"
              >{`trivia`}</Typography>
              <Box mt={1}>
                {questions.length > 0 && (
                  <Typography>{`${currentQuestionIndex + 1}/${
                    questions.length
                  }`}</Typography>
                )}
                {/* {currentQuestionIndex > 0 && (
                  <Typography>{`${numCorrect}/${currentQuestionIndex}`}</Typography>
                )} */}
              </Box>
            </Box>
            {!isLoading && questions && questions.length > 0 && (
              <Box
                className="question-box sizing"
                width="100%"
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <Typography variant="h6">
                  {questions[currentQuestionIndex].fields.question}
                </Typography>
                <Box>
                  {options.map((option, i) => (
                    <Card
                      className={`answer-box ${
                        i === selectedOptionIndex && showAnswer
                          ? "selected-option"
                          : undefined
                      }`}
                      key={option}
                      onClick={
                        showAnswer ? () => null : () => clickHandler(option, i)
                      }
                    >
                      {option}
                    </Card>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {showAnswer && (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={showSuccess ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {`The correct answer is ${questions[currentQuestionIndex].fields.correctOption}.`}
          </Alert>
        </Snackbar>
      )}
      {openAlert && (
        <AlertDialog
          open={openAlert}
          setOpen={setOpenAlert}
          buttonText={"restart"}
          content={alertContent}
          buttonClickHandler={() => setOpenAlert(false)}
          title="Your Score"
          isLoading={false}
        />
      )}
    </PageWrapper>
  );
};

export default Trivia;
