import { DEFAULT_COLOR} from "../components/KitPicker";

type ShirtProps = {
  fill?: string | null;
  badge?: string | null;
  link?: string | null;
};



export const Shirt = ({ fill, badge, link }: ShirtProps) => (
  <svg
    width="300px"
    height="300px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="a" />
    <g id="b" />
    <g id="c" />
    <g id="d" />
    <g id="e" />
    <g id="f" />
    <g id="g" />
    <g id="h" />
    <g id="i" />
    <g id="j" />
    <g id="k" />
    <g id="l" />
    <g id="m" />
    <g id="n" />
    <g id="o" />
    <g id="p" />
    <g id="q" />
    <g id="r" />
    <g id="s" />
    <g id="t" />
    <g id="u" />
    <g id="v" />
    <g id="w" />
    <g id="x" />
    <g id="y" />
    <g id="a`" />
    <g id="aa" />
    <g id="ab" />
    <g id="ac" />
    <g id="ad" />
    <g id="ae" />
    <g id="af" />
    <g id="ag">
      <path
        d="M38.89,7.69h-13.79l.8,7.11,6.68,3.06,6.31-10.18Z"
        fill={fill || DEFAULT_COLOR}
        id="ah"
      />

      <path
        d="M13,32.95l3.35-8.71-3.35-9.12-7.82,11.9c-.34,.52-.18,1.21,.36,1.53l7.47,4.4Z"
        fill={fill || DEFAULT_COLOR}
        id="ai"
      />

      <path
        d="M13,15.11l-3.64,15.69,3.64,2.15,2.74-7.13-2.74-10.7Z"
        fill={fill || DEFAULT_COLOR}
        id="aj"
      />

      <path
        d="M51,15.11l-4.75,10.5,4.75,7.33,7.47-4.4c.53-.31,.7-1.01,.36-1.53l-7.82-11.9Z"
        fill={fill || DEFAULT_COLOR}
        id="ak"
      />

      <path
        d="M51,15.11l3.64,15.69-3.64,2.15-2.74-7.13,2.74-10.7Z"
        fill={fill || DEFAULT_COLOR}
        id="al"
      />

      <path
        d="M14.14,13.6c-.63,0-1.14,.51-1.14,1.14V55.42c0,.63,.51,1.14,1.14,1.14H49.86c.63,0,1.14-.51,1.14-1.14V14.74c0-.63-.51-1.14-1.14-1.14h-6.16l-11.7,.43-11.7-.43h-6.16Z"
        fill={fill || DEFAULT_COLOR}
        id="am"
      />

      <path
        d="M32,14.03l2.9,5.91c.22,.45,.81,.57,1.2,.24l7.6-6.59-2.85-4.8-.54-.9c-.3-.5-.99-.61-1.42-.21l-6.89,6.34Z"
        fill={fill || DEFAULT_COLOR}
        id="an"
      />

      <path
        d="M24.42,7.45c-.29,.02-.57,.18-.73,.45l-.54,.9-2.85,4.8,7.6,6.59c.38,.33,.97,.21,1.2-.24l2.9-5.91-6.89-6.34c-.16-.15-.36-.23-.56-.24-.04,0-.08,0-.12,0Z"
        fill={fill || DEFAULT_COLOR}
        id="ao"
      />

      <path d="M5.03,29.4l6.97,4.11v21.9c0,1.18,.96,2.14,2.14,2.14H49.86c1.18,0,2.14-.96,2.14-2.14v-21.9l6.97-4.11c.49-.29,.84-.77,.97-1.32,.13-.55,.02-1.14-.29-1.62l-7.66-11.65v-.07c0-1.18-.96-2.14-2.14-2.14h-5.59l-3.1-5.22c-.3-.5-.8-.84-1.38-.92-.39-.06-.77,.05-1.12,.22h-13.35c-.35-.18-.72-.28-1.12-.22-.58,.08-1.08,.42-1.37,.92l-3.1,5.22h-5.59c-1.18,0-2.14,.96-2.14,2.14l-7.66,11.72c-.31,.47-.42,1.06-.29,1.62s.48,1.04,.97,1.32Zm52.96-1.84l.84-.55-.86,.67-5.96,3.51v-12.74l5.98,9.11Zm-15.57-14.17l-6.75,5.84-2.44-4.98,6.27-5.77,2.92,4.91Zm-10.42-.72l-4.33-3.98h8.66l-4.33,3.98Zm-7.5-4.18l6.27,5.77-2.44,4.98-6.75-5.84,2.92-4.9Zm-10.36,6.11h5.79l8.08,7c.5,.43,1.27,.27,1.55-.32l2.45-4.98,2.45,4.98c.29,.59,1.06,.74,1.55,.32l8.08-7h5.78c.08,0,.14,.06,.14,.14h0V55.42c0,.08-.06,.14-.14,.14H14.14c-.08,0-.14-.06-.14-.14V14.74h0c0-.08,.06-.14,.14-.14ZM6.01,27.56l5.99-9.11v12.74l-5.96-3.51-.51,.86,.48-.98Z" />
    </g>
    <g id="ap" />
    <g id="aq" />
    <g id="ar" />
    <g id="as" />
    <g id="at" />
    <g id="au" />
    <g id="av" />
    <g id="aw" />
    <g id="ax" />
    <g id="ay" />
    <g id="b`" />
    <g id="ba" />
    <g id="bb" />
    <g id="bc" />
    <g id="bd" />
    <g id="be" />
    <g id="bf" />
    {badge && (
      <text
        x="58%"
        y="50%"
        dominant-baseline="middle"
        text-anchor="middle"
        font-family="Arial"
        font-size="11"
        fill="black"
      >
        {`${badge}`}
      </text>
    )}
    {link && <image href={link} x="54%" y="38%" width="10" height="10" />}
  </svg>
);
