import React, { useEffect, useState, useContext } from "react";
import PageWrapper from "../pages/PageWrapper";
import { Prediction } from "../../types";
import { Box, Typography, Button } from "@mui/material";
import SkeletonFixture from "../SkeletonFixture";
import { useAuth0 } from "@auth0/auth0-react";
import LoginDialog from "../LoginDialog";
import { useNavigate, Link } from "react-router-dom";
import { getPredictions } from "../../services/prediction";
import { useQuery } from "react-query";
import { UserContext } from "../../context/UserContext";
import moment from "moment";
import PredictionRow from "../PredictionRow";
import { pointsDisclaimer } from "../../constants";
import PastFixtureRow from "../PastFixtureRow";
import { MATCH_COMPLETED } from "../../constants";

const Predictions = () => {
  const [predictions, setPredictions] = useState<Prediction[]>([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { user, isLoading: isLoadingUser } = useAuth0();
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (!user?.sub && !isLoadingUser) {
      setOpen(true);
    } else setOpen(false);
  }, [user, isLoadingUser]);

  const { isLoading, refetch: getPredictionsQuery } = useQuery(
    "get-predictions",
    async () => {
      if (user?.sub) {
        return await getPredictions({ auth0_id: user.sub });
      }
    },
    {
      enabled: false,
      onSuccess: (data) => {
        data.sort((a: Prediction, b: Prediction) => {
          return moment(a.fixture.dt_start).diff(b.fixture.dt_start, "seconds");
        });
        setPredictions(data);
      },
    }
  );

  useEffect(() => {
    if (user?.sub) {
      getPredictionsQuery();
    }
  }, [user, getPredictionsQuery]);

  return (
    <PageWrapper>
      <Box display="flex" justifyContent="center">
        <Box>
          <Box mb={2}>
            <Typography
              variant="h5"
              className="section-header"
            >{`my predictions`}</Typography>
          </Box>

          {!isLoading && user?.sub && userContext?.user && (
            <Box mb={2}>
              <Typography>{`Available Points: ${userContext?.user?.points}`}</Typography>
            </Box>
          )}

          {!isLoading && predictions.length > 0 && (
            <Box mt={1} mb={2} className=" disclaimer-box">
              <Typography className="predict-helper">
                {pointsDisclaimer}
                <Link
                  to="/about"
                  style={{ marginLeft: "5px", color: "#a0a4b8ff" }}
                >
                  Learn More
                </Link>
              </Typography>
            </Box>
          )}

          {(isLoading || isLoadingUser) && (
            <Box>
              <SkeletonFixture />
              <SkeletonFixture />
              <SkeletonFixture />
              <SkeletonFixture />
              <SkeletonFixture />
            </Box>
          )}
          {!isLoading && user?.sub && (
            <Box>
              {predictions.map((p) => {
                return p.fixture.status_long === MATCH_COMPLETED &&
                  ["settled_won", "settled_lost"].includes(p.status) ? null : (
                  <PredictionRow key={p.id} prediction={p} />
                );
              })}
            </Box>
          )}

          <Box mb={2}>
            <Typography
              variant="h5"
              className="section-header"
            >{`past predictions`}</Typography>
          </Box>
          {!isLoading && user?.sub && (
            <Box>
              {[...predictions].reverse().map((p) => {
                return p.fixture.status_long === MATCH_COMPLETED &&
                  ["settled_won", "settled_lost"].includes(p.status) ? (
                  <PastFixtureRow
                    key={p.id}
                    fixture={p.fixture}
                    prediction={p}
                  />
                ) : null;
              })}
            </Box>
          )}

          {!isLoadingUser && !user?.sub && (
            <Box>
              <SkeletonFixture animation={false} />
              <SkeletonFixture animation={false} />
              <SkeletonFixture animation={false} />
              <SkeletonFixture animation={false} />
              <SkeletonFixture animation={false} />
            </Box>
          )}
          {!isLoading &&
            !isLoadingUser &&
            predictions.length === 0 &&
            user?.sub && (
              <Box>
                <Typography>
                  Check out the match schedule to start making predictions.
                </Typography>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    onClick={() => navigate("/matches")}
                  >
                    View Schedule
                  </Button>
                </Box>
              </Box>
            )}
        </Box>
      </Box>
      <LoginDialog
        open={open}
        setOpen={setOpen}
        title="Predict the Winner"
        description="You must have an account in order to set predictions."
      />
    </PageWrapper>
  );
};

export default Predictions;
