import axios from "axios";
import { headers } from "./";

type CompetitionInput = {
  auth0_id?: string | null;
  competition_id: string | null | undefined;
};

type SwitchCompetitionInput = {
  auth0_id: string | null | undefined;
  competition_id: number;
};

export const getCompetition = async (competition: CompetitionInput) => {
  const comp = await axios.post("/.netlify/functions/getCompetition", {
    data: JSON.stringify(competition),
    headers,
  });

  return comp.data;
};

export const getCompetitions = async () => {
  const comps = await axios.post("/.netlify/functions/getCompetitions", {
    headers,
  });

  return comps.data;
};

export const switchCompetition = async (
  competition: SwitchCompetitionInput
) => {
  const comp = await axios.post("/.netlify/functions/switchCompetition", {
    data: JSON.stringify(competition),
    headers,
  });

  return comp.data;
};
