import React, { useState, useEffect, useContext } from "react";
import { calculateTimeLeft } from "../services/util";
import { Typography, Box } from "@mui/material";
import PickWinner from "./PickWinner";
import { CompetitionContext } from "../context/CompetitionContext";

type Props = {
  number: number;
  title: string;
  m: number;
  p: number;
  hideLeadingZero?: boolean;
};

export const CountdownElement = ({
  number,
  title,
  m,
  p,
  hideLeadingZero,
}: Props) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" m={m} p={p}>
      <Typography className="countdown-title">{title}</Typography>
      <Typography className="countdown-number">
        {hideLeadingZero ? number : ("0" + number).slice(-2)}
      </Typography>
    </Box>
  );
};

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const competitionContext = useContext(CompetitionContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const { days, hours, minutes, seconds, showCountdown } = timeLeft;

  return (
    <>
      {showCountdown && (
        <Box display="flex" justifyContent="center">
          <CountdownElement number={days} title={"days"} m={1} p={1} />
          <Box alignSelf="flex-start" className="divider">
            <Typography variant="h2">{`.`}</Typography>
          </Box>
          <CountdownElement number={hours} title={"hours"} m={1} p={1} />
          <Box alignSelf="flex-start" className="divider">
            <Typography variant="h2">{`.`}</Typography>
          </Box>
          <CountdownElement number={minutes} title={"minutes"} m={1} p={1} />
          <Box alignSelf="flex-start" className="divider">
            <Typography variant="h2">{`.`}</Typography>
          </Box>
          <CountdownElement number={seconds} title={"seconds"} m={1} p={1} />
        </Box>
      )}
      {competitionContext?.competition?.name === "world cup 2023" && (
        <PickWinner isTournamentUpcoming={showCountdown} />
      )}
    </>
  );
};

export default Countdown;
