import React from "react";
import Schedule from "../Schedule";
import Countdown from "../Countdown";
import PageWrapper from "../pages/PageWrapper";

const Home = () => {
  return (
    <PageWrapper>
      <Countdown />
      <Schedule />
    </PageWrapper>
  );
};

export default Home;
