import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useQuery } from "react-query";
import SkeletonFixture from "./SkeletonFixture";
import { getTable } from "../services/fixture";
import { TeamGroup } from "../types";
import { CompetitionContext } from "../context/CompetitionContext";
import Logo from "./Logo";
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
  groups: TeamGroup[];
  groupName: string;
  showGroups: boolean;
};

const GroupRow = ({ groups, groupName, showGroups }: Props) => {
  const groupsFiltered = groups.filter((g) => g.group === groupName);
  return (
    <Box mt={2} className="table">
      <TableContainer component={Paper} className="leaderboard-container">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width="2%"></TableCell>
              <TableCell>
                {showGroups ? `Group ${groupName}` : "Team"}
              </TableCell>
              <TableCell align="right">Points</TableCell>
              <TableCell align="right">Matches</TableCell>
              <TableCell align="right">GD</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupsFiltered.map((row, i) => (
              <TableRow key={i}>
                <TableCell scope="row" className="small rank" width="2%">
                  {i + 1}
                </TableCell>
                <TableCell scope="row" className="small" width="55%">
                  <Box display="flex">
                    <Typography width="25px">
                      <Logo
                        logo={row.logo}
                        logo_url={row.logo_url}
                        name={row.name}
                      />
                    </Typography>
                    <Typography
                      style={{ fontSize: "12px" }}
                    >{`${row.name}`}</Typography>
                  </Box>
                </TableCell>
                <TableCell align="right" className="small" width="10%">
                  {row.points}
                </TableCell>
                <TableCell align="right" className="small">
                  {row.matches}
                </TableCell>
                <TableCell align="right" className="small">
                  {row.goal_diff || 0}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const Groups = () => {
  const { user, isLoading: userLoading } = useAuth0();
  const competitionContext = useContext(CompetitionContext);
  const [groups, setGroups] = useState<TeamGroup[]>([]);
  const groupLabels = groups.map((g) => g.group);
  const groupNames = groupLabels
    .filter((x: string, i: number, a) => a.indexOf(x) === i)
    .sort();
  const { isLoading, refetch: getTableQuery } = useQuery(
    "get-table",
    async () => {
      return await getTable({
        auth0_id: user?.sub,
        competition_id: localStorage.getItem("last_competition_id"),
      });
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setGroups(data);
      },
    }
  );

  useEffect(() => {
    if (!userLoading) {
      getTableQuery();
    }
  }, [getTableQuery, userLoading]);

  return (
    <Box display="flex" justifyContent="center">
      <Box>
        <Box mb={2}>
          <Typography variant="h5" className="section-header">
            {competitionContext?.competition?.show_groups ? `groups` : `table`}
          </Typography>
          <Box mt={1} className="sizing">
            <Typography>{`3 points for a win, 1 for a draw, 0 for a loss`}</Typography>
          </Box>

          {isLoading && (
            <Box mt={2}>
              <SkeletonFixture />
              <SkeletonFixture />
              <SkeletonFixture />
              <SkeletonFixture />
              <SkeletonFixture />
            </Box>
          )}
          {!isLoading && groups.length > 0 && (
            <>
              {groupNames.map((name, i) => (
                <GroupRow
                  groups={groups}
                  groupName={name}
                  key={i}
                  showGroups={
                    competitionContext?.competition?.show_groups || false
                  }
                />
              ))}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Groups;
