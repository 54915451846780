import React, { useEffect, useState, useContext } from "react";
import PageWrapper from "./PageWrapper";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useQuery } from "react-query";
import { getTable } from "../../services/fixture";
import { useAuth0 } from "@auth0/auth0-react";
import { TeamGroup, KitLog } from "../../types";
import { CompetitionContext } from "../../context/CompetitionContext";
import { getKitLog, getKitLogSummary } from "../../services/kit";
import KitPicker from "../KitPicker";

const Kit = () => {
  const competitionContext = useContext(CompetitionContext);
  const { user, isLoading: userLoading } = useAuth0();
  const [teams, setTeams] = useState<TeamGroup[]>([]);
  const [latestLog, setLatestLog] = useState<KitLog | null>(null);
  const [logSummary, setLogSummary] = useState<Record<string, number>>({});

  const { isLoading, refetch: getTableQuery } = useQuery(
    "get-table",
    async () => {
      return await getTable({
        auth0_id: user?.sub,
        competition_id: localStorage.getItem("last_competition_id"),
      });
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setTeams(data);
      },
    }
  );

  const { isLoading: isLogLoading, refetch: getKitLogQuery } = useQuery(
    "get-kit-log",
    async () => {
      return await getKitLog({
        competition_id: localStorage.getItem("last_competition_id"),
        auth0_id: user?.sub,
      });
    },
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.length > 0) {
          setLatestLog(data[0]);
        } else {
          setLatestLog(null);
        }
      },
    }
  );

  const { isLoading: isLogSummaryLoading, refetch: getKitLogSummaryQuery } =
    useQuery(
      "get-kit-log-summary",
      async () => {
        return await getKitLogSummary({
          competition_id: localStorage.getItem("last_competition_id"),
          auth0_id: user?.sub,
        });
      },
      {
        enabled: false,
        onSuccess: (data) => {
          setLogSummary(data);
        },
      }
    );

  useEffect(() => {
    if (!userLoading) {
      getTableQuery();
    }
  }, [getTableQuery, userLoading]);

  useEffect(() => {
    getKitLogQuery();
  }, [getKitLogQuery]);

  useEffect(() => {
    getKitLogSummaryQuery();
  }, [getKitLogSummaryQuery]);

  return (
    <PageWrapper>
      <Box display="flex" justifyContent="center">
        <Box>
          <Box mb={2}>
            <Typography
              variant="h5"
              className="section-header"
            >{`collaborative kit`}</Typography>
            <Box mt={1} className="sizing">
              <Typography>{`one kit for us all`}</Typography>
            </Box>
          </Box>
          {!isLoading &&
          !userLoading &&
          competitionContext?.competition?.id &&
          !isLogLoading &&
          !isLogSummaryLoading ? (
            <KitPicker
              competitionId={competitionContext?.competition?.id}
              teams={teams}
              auth0Id={user?.sub}
              latestLog={latestLog}
              logSummary={logSummary}
            />
          ) : (
            <CircularProgress />
          )}
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default Kit;
